define('smoke-and-mirrors/-debug/edge-visualization/visualization', ['exports', 'smoke-and-mirrors/-private/radar/models/geography', 'smoke-and-mirrors/-private/radar/models/container'], function (exports, _smokeAndMirrorsPrivateRadarModelsGeography, _smokeAndMirrorsPrivateRadarModelsContainer) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var SYS_WIDTH = 250;

  var Visualization = (function () {
    function Visualization(component) {
      this.component = component;
      this.minimumMovement = Math.floor(component.defaultHeight / 2);
      this.radar = component.radar;
      this.satellites = [];
      this.cache = [];
      this.setupViewport();
      this.render();
    }

    _createClass(Visualization, [{
      key: 'setupViewport',
      value: function setupViewport() {
        this.wrapper = document.createElement('div');
        this.wrapper.className = 'sm_visualization-wrapper';

        this.container = document.createElement('div');
        this.container.className = 'sm_visualization-container';
        this.wrapper.appendChild(this.container);

        this.sky = document.createElement('div');
        this.sky.className = 'sm_visualization-skyline';
        this.container.appendChild(this.sky);

        this.telescope = document.createElement('div');
        this.telescope.className = 'sm_visualization-telescope';
        this.container.appendChild(this.telescope);

        this.visAbove = document.createElement('div');
        this.visAbove.className = 'sm_visualization-visible';
        this.container.appendChild(this.visAbove);

        this.visBelow = document.createElement('div');
        this.visBelow.className = 'sm_visualization-visible';
        this.container.appendChild(this.visBelow);

        this.screen = document.createElement('div');
        this.screen.className = 'sm_visualization-screen';
        this.container.appendChild(this.screen);

        document.body.appendChild(this.wrapper);
      }
    }, {
      key: 'currentOffsetAdjustment',
      value: function currentOffsetAdjustment() {
        var currOffsets = this.radar.currentOffsets;

        if (currOffsets !== null) {
          var _scrollY2 = currOffsets.top;
          var _scrollX2 = currOffsets.left;
          var _scrollY = this.radar.scrollY;
          var _scrollX = this.radar.scrollX;
          var dY = _scrollY2 - _scrollY;
          var dX = _scrollX2 - _scrollX;

          return { dY: dY, dX: dX };
        }

        return { dY: 0, dX: 0 };
      }
    }, {
      key: 'applySatelliteStyles',
      value: function applySatelliteStyles(element, geography) {
        var adj = this.currentOffsetAdjustment();
        var left = SYS_WIDTH;

        element.style.height = geography.height + 'px';
        element.style.top = geography.top - adj.dY + 'px';
        element.style.left = left + 'px';
      }
    }, {
      key: 'applySatelliteMirrorStyles',
      value: function applySatelliteMirrorStyles(element, componentElement, compare) {
        var adj = this.currentOffsetAdjustment();
        var geography = new _smokeAndMirrorsPrivateRadarModelsGeography['default'](componentElement);
        var left = 2 * SYS_WIDTH;
        var errorLevel = false;

        element.style.height = geography.height + 'px';
        element.style.top = geography.top + 'px';
        element.style.left = left + 'px';

        var diff = Math.abs(geography.top - compare.top + adj.dY);

        if (diff > this.minimumMovement) {
          errorLevel = true;
        }

        element.setAttribute('hasErrors', errorLevel ? 'true' : 'false');
      }
    }, {
      key: 'styleViewport',
      value: function styleViewport() {
        var edges = this.component._edges;
        var _radar = this.radar;
        var planet = _radar.planet;
        var skyline = _radar.skyline;

        this.container.style.height = planet.height + 'px';

        Visualization.applyVerticalStyles(this.telescope, planet);
        Visualization.applyVerticalStyles(this.sky, skyline);

        Visualization.applyVerticalStyles(this.screen, new _smokeAndMirrorsPrivateRadarModelsGeography['default'](_smokeAndMirrorsPrivateRadarModelsContainer['default']));

        Visualization.applyVerticalStyles(this.visAbove, {
          top: edges.visibleTop,
          height: edges.viewportTop - edges.visibleTop
        });

        Visualization.applyVerticalStyles(this.visBelow, {
          top: edges.viewportBottom,
          height: edges.visibleBottom - edges.viewportBottom
        });
      }
    }, {
      key: 'makeSatellite',
      value: function makeSatellite() {
        var satellite = undefined;
        var mirror = undefined;

        if (this.cache.length) {
          satellite = this.cache.pop();
        } else {
          satellite = document.createElement('div');
          satellite.className = 'sm_visualization-satellite';
        }
        if (satellite.mirrorSatellite) {
          mirror = satellite.mirrorSatellite;
        } else {
          mirror = document.createElement('div');
          mirror.className = 'sm_visualization-mirror';
          mirror.siblingSatellite = satellite;
          satellite.mirrorSatellite = mirror;
        }
        this.satellites.push(satellite);
        this.container.insertBefore(satellite, this.container.firstElementChild);
        this.container.insertBefore(mirror, this.container.firstElementChild);
      }
    }, {
      key: 'makeSatellites',
      value: function makeSatellites() {
        var length = this.radar.satellites.length;

        var isShrinking = this.satellites.length > length;

        while (this.satellites.length !== length) {
          if (isShrinking) {
            var satellite = this.satellites.pop();

            satellite.parentNode.removeChild(satellite);
            satellite.mirrorSatellite.parentNode.removeChild(satellite.mirrorSatellite);
            this.cache.push(satellite);
          } else {
            this.makeSatellite();
          }
        }
        this.styleSatellites();
      }
    }, {
      key: 'styleSatellites',
      value: function styleSatellites() {
        var _this = this;

        var sats = this.satellites;

        this.radar.satellites.forEach(function (sat, index) {
          var element = sats[index];
          var satIndex = sat.component.get('index');

          _this.applySatelliteStyles(element, sat.geography);
          element.setAttribute('viewState', sat.component._contentInserted ? 'visible' : 'culled');
          element.mirrorSatellite.setAttribute('viewState', sat.component._contentInserted ? 'visible' : 'culled');
          element.setAttribute('index', satIndex);
          element.mirrorSatellite.setAttribute('index', satIndex);
          element.innerText = satIndex;
          _this.applySatelliteMirrorStyles(element.mirrorSatellite, sat.component.element, sat.geography);
          element.mirrorSatellite.innerText = satIndex;
        });
      }
    }, {
      key: 'render',
      value: function render() {
        this.styleViewport();
        this.makeSatellites();
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        this.wrapper.parentNode.removeChild(this.wrapper);
        this.wrapper = null;
        this.radar = null;
        this.component = null;
        this.satellites.forEach(function (satellite) {
          satellite.mirrorSatellite = null;
          satellite.siblingSatellite = null;
          if (satellite.parentNode) {
            satellite.parentNode.removeChild(satellite);
          }
        });
        this.satellites = null;
        this.cache.forEach(function (satellite) {
          satellite.mirrorSatellite = null;
          satellite.siblingSatellite = null;
          if (satellite.parentNode) {
            satellite.parentNode.removeChild(satellite);
          }
        });
        this.cache = null;
      }
    }], [{
      key: 'applyVerticalStyles',
      value: function applyVerticalStyles(element, geography) {
        element.style.height = geography.height + 'px';
        element.style.top = geography.top + 'px';
      }
    }, {
      key: 'applyStyles',
      value: function applyStyles(element, geography) {
        Visualization.applyVerticalStyles(element, geography);
        element.style.width = geography.width + 'px';
        element.style.left = geography.left + 'px';
      }
    }]);

    return Visualization;
  })();

  exports['default'] = Visualization;
});
/* global document */