define('liquid-wormhole/index', ['exports', 'liquid-fire/constraint'], function (exports, _liquidFireConstraint) {
  exports.target = target;
  exports.onOpenWormhole = onOpenWormhole;
  exports.onCloseWormhole = onCloseWormhole;

  function target(name) {
    return new _liquidFireConstraint['default']('parentElementClass', '' + name);
  }

  function onOpenWormhole() {
    return new _liquidFireConstraint['default']('newValue', function (value) {
      return value !== null;
    });
  }

  function onCloseWormhole() {
    return new _liquidFireConstraint['default']('newValue', function (value) {
      return value === null;
    });
  }
});