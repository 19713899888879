define('ember-cli-crudities/components/form-datetime', ['exports', 'ember-cli-crudities/templates/components/form-datetime', 'ember-cli-crudities/mixins/boundvalue'], function (exports, _formDatetime, _boundvalue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_boundvalue.default, {
    layout: _formDatetime.default,
    tagName: undefined,
    type: 'datetime',
    date: Ember.computed('value', {
      get: function get() {
        var dt = this.get('value');
        if (Ember.isEmpty(dt)) {
          return undefined;
        }
        dt = new Date(dt);
        var month = dt.getMonth() + 1;
        if (month < 10) {
          month = '0' + month;
        }
        var day = dt.getDate();
        if (day < 10) {
          day = '0' + day;
        }
        var rv = dt.getFullYear() + '-' + month + '-' + day;
        return rv;
      },
      set: function set(key, value) {
        if (!Ember.isEmpty(value)) {
          var components = value.split('-');
          var oldDT = this.get('value');
          if (Ember.isEmpty(oldDT)) {
            oldDT = new Date(0);
          } else {
            oldDT = new Date(oldDT);
          }
          oldDT.setFullYear(components[0]);
          oldDT.setMonth(components[1] - 1);
          oldDT.setDate(components[2]);
          this.set('value', oldDT.toISOString());
        }
        return value;
      }
    }),
    time: Ember.computed('value', {
      get: function get() {
        var dt = this.get('value');
        if (Ember.isEmpty(dt)) {
          return undefined;
        }
        dt = new Date(dt);
        var hours = dt.getHours();
        if (hours < 10) {
          hours = '0' + hours;
        }
        var minutes = dt.getMinutes();
        if (minutes < 10) {
          minutes = '0' + minutes;
        }
        var rv = hours + ':' + minutes;
        return rv;
      },
      set: function set(key, value) {
        if (!Ember.isEmpty(value)) {
          var components = value.split(':');
          var oldDT = this.get('value');
          if (Ember.isEmpty(oldDT)) {
            oldDT = new Date(0);
          } else {
            oldDT = new Date(oldDT);
          }
          oldDT.setHours(components[0]);
          oldDT.setMinutes(components[1]);
          this.set('value', oldDT.toISOString());
        }
        return value;
      }
    }),

    actions: {
      validate: function validate(parent) {
        parent.validate();
      }
    }
  });
});