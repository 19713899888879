define('ember-cli-crudities/components/tomany-lines', ['exports', 'ember-cli-crudities/templates/components/tomany-lines'], function (exports, _tomanyLines) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _tomanyLines.default,
    tagName: 'table',
    classNames: ['table', 'table-condensed', 'table-striped', 'tomany'],

    reorderAction: 'reorderItems',

    onLengthChange: Ember.observer('related.length', function () {
      var _this = this;

      Ember.run.later(this, function () {
        if (!_this.get('isDestroyed')) {
          _this.resize();
        }
      }, 150);
    }),

    resize: function resize() {
      this.$('tr').css('display', 'block');
      var width = 0;
      var padderColspan = parseInt(this.get('padderColspan'));
      var labelsRow = !!this.get('labelsRow');
      var header_offset = 1;
      if (padderColspan > 1) {
        header_offset = 2 - padderColspan;
      }

      for (var i = padderColspan; i < this.get('column_count'); i++) {
        width = Math.max(Math.max.apply(Math, this.$('tbody tr td:nth-child(' + (i + 1) + ')').map(function () {
          return window.$(this).innerWidth();
        }).get()), labelsRow ? this.$('thead tr.headers th:nth-child(' + (i + header_offset) + ')').innerWidth() : 0);
        this.$('tbody tr td:nth-child(' + (i + 1) + ')').css('min-width', width);
        if (labelsRow) {
          this.$('thead tr.headers th:nth-child(' + (i + header_offset) + ')').css('min-width', width);
        }
      }
      if (padderColspan > 0 && labelsRow) {
        width = 0;
        for (var j = 0; j < padderColspan; j++) {
          width += this.$('tbody tr td:nth-child(' + (j + 1) + ')').innerWidth();
        }
        this.$('thead tr.headers th:nth-child(1)').css('min-width', width);
      }
      this.$('tr').css('display', 'flex');
    },

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super();
      Ember.run.later(this, function () {
        if (!_this2.get('isDestroyed')) {
          _this2.resize();
        }
      }, 150);
      // window.$(window).on('resize', this.resize.bind(this));
      // window.$(window).on('orientationchange', this.resize.bind(this));
    },
    willDestroy: function willDestroy() {
      // window.$(window).off('resize', this.resize.bind(this));
      // window.$(window).off('orientationchange', this.resize.bind(this));
    },


    actions: {
      reorderItems: function reorderItems(items) {
        this.sendAction('reorderAction', items);
      }
    }

  });
});