define('ember-cli-crudities/utils/duration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.seconds_to_str = seconds_to_str;
  exports.str_to_seconds = str_to_seconds;


  function leftPad(value, length) {
    if (Ember.isEmpty(length)) {
      length = 2;
    }
    value = value.toString();

    while (value.length < length) {
      value = '0' + value;
    }

    return value;
  }

  function seconds_to_str(value, withSeconds, separator) {
    if (Ember.isEmpty(separator)) {
      separator = ' ';
    }
    var duration = window.moment.duration(value, 'seconds');
    var rv = '';
    // if (duration.years()) {
    //   rv = `${duration.years()} `;
    // }
    // if (duration.months()) {
    //   rv += `${duration.months()} `;
    // }
    // if (duration.weeks()) {
    //   rv += `${duration.weeks()} `;
    // }
    if (duration.days()) {
      rv += '' + parseInt(Math.floor(duration.asDays())) + separator;
    }
    rv += leftPad(duration.hours()) + ':' + leftPad(duration.minutes());
    if (withSeconds) {
      rv += ':' + leftPad(duration.seconds());
    }

    return rv;
  }

  function str_to_seconds(value, withSeconds) {
    var duration = value;
    if (Ember.isEmpty(value)) {
      duration = '0';
    }
    if (value === '0.0') {
      duration = '0';
    }
    duration = duration.replace(' ', '.');

    if (!withSeconds) {
      duration += ':0';
    }

    // return window.moment.duration(duration);

    var timeChunks = duration.split(':');
    if (timeChunks.length === 1) {
      return parseInt(duration);
    }
    var total = parseInt(timeChunks[1]) * 60;
    if (withSeconds) {
      total += parseInt(timeChunks[2]);
    }
    var daysHours = timeChunks[0].split('.');
    if (daysHours.length === 1) {
      total += parseInt(timeChunks[0]) * 3600;
    } else {
      total += parseInt(daysHours[0]) * 3600 * 24 + parseInt(daysHours[1]) * 3600;
    }

    return total;
  }
});