define('ember-cli-crudities/components/form-tomany-table', ['exports', 'ember-cli-crudities/components/form-tomany-stack', 'ember-cli-crudities/templates/components/form-tomany-table'], function (exports, _formTomanyStack, _formTomanyTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formTomanyStack.default.extend({
    layout: _formTomanyTable.default,
    classNames: ['tomany-table'],

    actionColumns: Ember.computed('field.extra.customActions', 'field.extra.customActions.length', 'field.extra.allowOpen', 'field.extra.forceActions', function () {
      var forceActions = this.get('field.extra.forceActions');
      if (Ember.isEmpty(forceActions)) {
        forceActions = [];
      }
      var actionsToShow = this.get('custom_actions').filter(function (act) {
        if (!act.hide && !act.hideInList && !act.hideInToMany) {
          return true;
        }
        return forceActions.indexOf(act.slug) > -1;
      });
      var rv = parseInt(actionsToShow.length ? actionsToShow.length : 0) + parseInt(this.get('field.extra.allowOpen') ? 1 : 0);
      return rv;
    }),

    column_count: Ember.computed('field.fields.length', 'actionColumns', function () {
      var readonly = this.get('readonly');
      var rv = parseInt(this.get('field.fields.length')) + parseInt(readonly !== true ? 1 : 0) + parseInt(!readonly && this.get('field.extra.sortableBy') ? 1 : 0) + parseInt(this.get('actionColumns'));
      return rv;
    })
  });
});