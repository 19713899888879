define('smoke-and-mirrors/services/in-viewport', ['exports', 'ember', 'smoke-and-mirrors/-private/radar/models/radar'], function (exports, _ember, _smokeAndMirrorsPrivateRadarModelsRadar) {
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend({

    radar: null,
    minimumMovement: 15,
    resizeDebounce: 64,

    register: function register(component) {
      this.radar.register(component);
    },

    unregister: function unregister(component) {
      this.radar.unregister(component);
    },

    _activateRadar: function _activateRadar() {
      var resizeDebounce = this.get('resizeDebounce');
      var minimumMovement = this.get('minimumMovement');

      this.radar = new _smokeAndMirrorsPrivateRadarModelsRadar['default']({
        sky: document.body,
        telescope: window,
        minimumMovement: minimumMovement,
        resizeDebounce: resizeDebounce
      });
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.radar.destroy();
      this.radar = null;
    },

    init: function init() {
      this._super.apply(this, arguments);
      this._activateRadar();
    }

  });
});