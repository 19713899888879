define('ember-cli-crudities/components/app-menu', ['exports', 'ember-cli-crudities/components/app-dashboard', 'ember-cli-crudities/templates/components/app-menu'], function (exports, _appDashboard, _appMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _appDashboard.default.extend({
    layout: _appMenu.default,
    tagName: 'ul',

    routing: Ember.inject.service('-routing'),
    currentApp: null,
    currentModel: null,

    routeChange: Ember.observer('routing.currentState', function () {
      try {
        var router = this.get('routing.currentState.emberRouter.router');
        if (Ember.isEmpty(router)) {
          var App = Ember.getOwner(this);
          router = App.lookup('router:main').get('currentState.routerJs');
        }
        var state = Ember.get(router, 'currentHandlerInfos');
        var app = Ember.get(state[2].params, 'app_name');
        if (app) {
          this.set('currentApp', app);
        } else {
          this.set('currentApp', null);
        }
        var model = Ember.get(state[2].params, 'model_name');
        if (model) {
          this.set('currentModel', model);
        } else {
          this.set('currentModel', null);
        }
      } catch (e) {
        console.warn(e);
        this.set('currentApp', null);
        this.set('currentModel', null);
      }
    }),

    init: function init() {
      this._super();
      Ember.run.later(this, this.routeChange, 150);
    },


    actions: {
      closeAndTransition: function closeAndTransition(route, app_name, model_name, id) {
        if (this.get('parent')) {
          this.set('parent.open', false);
        }
        if (app_name) {
          if (model_name) {
            if (!Ember.isEmpty(id)) {
              this.get('routing').transitionTo(route, [app_name, model_name, id]);
            } else {
              this.get('routing').transitionTo(route, [app_name, model_name]);
            }
          } else {
            this.get('routing').transitionTo(route, [app_name]);
          }
        } else {
          this.get('routing').transitionTo(route);
        }
      }
    }
  });
});