define('ember-cli-crudities/components/cl-legend', ['exports', 'ember-cli-crudities/templates/components/cl-legend'], function (exports, _clLegend) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _clLegend.default,
    tagName: 'table',
    classNames: 'table'
  });
});