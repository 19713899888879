define('ember-cli-crudities/components/form-tabset', ['exports', 'ember-cli-crudities/templates/components/form-tabset'], function (exports, _formTabset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _formTabset.default,
    tagName: null,

    activeIndex: 0,

    /** For compatibility with change-form actions **/
    fields: Ember.computed('tabs', 'tabs.length', 'tabs.@each.fields', function () {
      var rv = new Ember.A();
      var tabs = this.get('tabs');
      tabs.forEach(function (tab) {
        tab.get('fields').map(function (field) {
          rv.pushObject(field);
        });
      });
      return rv;
    }),

    actions: {
      activateTab: function activateTab(index) {
        this.set('activeIndex', index);
      }
    }
  });
});