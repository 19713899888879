define('ember-cli-crudities/mixins/configurable-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super();
      var appConfig = Ember.getOwner(this).resolveRegistration('config:environment');
      var modelLoader = Ember.get(appConfig, 'ember-cli-crudities.model-loader');
      if (modelLoader !== undefined) {
        this.set('modelLoader', Ember.inject.service(modelLoader));
      }
    }
  });
});