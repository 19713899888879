define('ember-cli-crudities/components/cl-headers', ['exports', 'ember-cli-crudities/templates/components/cl-headers'], function (exports, _clHeaders) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _clHeaders.default,
    tagName: 'tr'
  });
});