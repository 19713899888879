define('ember-cli-crudities/components/form-foreignkey', ['exports', 'ember-cli-crudities/templates/components/form-foreignkey', 'ember-cli-crudities/components/foreignkey-base', 'ember-cli-crudities/mixins/boundvalue'], function (exports, _formForeignkey, _foreignkeyBase, _boundvalue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _foreignkeyBase.default.extend(_boundvalue.default, {
    layout: _formForeignkey.default,
    type: 'foreignkey',
    isExpanded: false,

    _validate_current_selection: function _validate_current_selection(content) {
      var valueId = '' + this.get('value.id');
      var firstId = undefined;
      var first = void 0;
      /* this console.log is a weird workaround but it works
       * if the values in that console.log are not accessed prior the code after it
       * things break in edit forms */
      console.log({ content: content });
      if (this.get('extra.autoSetUnique') && !Ember.isEmpty(content) && parseInt(Ember.get(content, 'length')) === 1 || this.get('extra.autoFirstAfterChange') && !Ember.isEmpty(content)) {
        try {
          first = content.firstObject;
          firstId = first.get('id');
        } catch (e) {
          first = content[0];
          if (!Ember.isEmpty(first)) {
            firstId = first.id;
          }
        }
        firstId = String(firstId);
      }
      if (firstId !== 'undefined' && (this.get('extra.autoSetUnique') && !Ember.isEmpty(content) && parseInt(Ember.get(content, 'length')) === 1 || this.get('extra.autoFirstAfterChange') && !Ember.isEmpty(content)) && firstId !== valueId) {
        if (!this.get('isDestroyed')) {
          this.set('value', first);
        }
      } else if (!this.get('extra.dontSeed') && valueId !== 'undefined' && (Ember.isEmpty(content) || !content.any(function (record) {
        return '' + record.get('id') === valueId;
      }))) {
        if (!this.get('isDestroyed')) {
          this.set('value', undefined);
        }
      }
    },
    _do_search: function _do_search(term, clean) {
      var _this = this;

      var rv = this._super(term, clean);
      if (!this.get('isDestroyed')) {
        if (rv.then) {
          rv.then(function (results) {
            _this._validate_current_selection(results);
          });
        } else {
          this._validate_current_selection(rv);
        }
      }
      return rv;
    }
  });
});