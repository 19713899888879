define('ember-cli-crudities/components/form-tomany-list-detail', ['exports', 'ember-cli-crudities/components/form-tomany-slide', 'ember-cli-crudities/templates/components/form-tomany-list-detail'], function (exports, _formTomanySlide, _formTomanyListDetail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formTomanySlide.default.extend({
    layout: _formTomanyListDetail.default,

    record: null,

    _list_class: 'col-xs-12',
    _detail_class: 'col-xs-12',

    listClass: Ember.computed.or('field.extra.listClass', '_list_class'),
    detailClass: Ember.computed.or('field.extra.detailClass', '_detail_class'),

    actions: {
      select: function select(record) {
        this.set('record', record);
      }
    }
  });
});