define('ember-cli-crudities/components/conditional-display', ['exports', 'ember-cli-crudities/templates/components/conditional-display', 'ember-cli-crudities/mixins/condition-evaluator'], function (exports, _conditionalDisplay, _conditionEvaluator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_conditionEvaluator.default, {
    layout: _conditionalDisplay.default,

    display: Ember.computed('condition', 'value', function () {
      var condition = this.get('condition');
      if (condition === undefined) {
        return true;
      }

      var value = this.get('value');
      return this.evaluate(condition, value);
    })
  });
});