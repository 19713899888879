define('ember-cli-crudities/mixins/with-defaults', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    get_default_for_field: function get_default_for_field(field) {
      var def = Ember.get(field, 'extra.default');
      if (def) {
        var relatedModel = Ember.get(field, 'extra.related_model');
        if (!Ember.isEmpty(relatedModel)) {
          def = this.get('store').findRecord(relatedModel, def);
        }
      }
      return def;
    }
  });
});