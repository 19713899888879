define('ember-cli-crudities/components/form-image', ['exports', 'ember-cli-crudities/components/form-file', 'ember-cli-crudities/templates/components/form-image'], function (exports, _formFile, _formImage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formFile.default.extend({
    layout: _formImage.default,
    type: 'image',
    _width: '175px',
    _height: '175px',
    _tn_width: '75px',
    _tn_height: '75px',

    width: Ember.computed.or('extra.width', '_width'),
    height: Ember.computed.or('extra.height', '_height'),

    tnWidth: Ember.computed.or('extra.tnWidth', '_tn_width'),
    tnHeight: Ember.computed.or('extra.tnHeight', '_tn_height'),

    background: Ember.computed.or('value', 'extra.placeholder'),

    style: Ember.computed('width', 'height', 'background', function () {
      var width = this.get('width');
      var height = this.get('height');

      var rv = 'border: 1px solid #999; display: inline-block; width: ' + width + '; height:' + height + ';';

      var image = this.get('background');
      if (image) {
        rv += ' position: relative; background-repeat: no-repeat; background-position: center center; background-size: contain; background-image: url(' + image + ')';
      } else {
        rv += ' display: flex; justify-content: center; align-items: center;';
      }

      return rv;
    }),

    tnStyle: Ember.computed('tnWidth', 'tnHeight', function () {
      var width = this.get('tnWidth');
      var height = this.get('tnHeight');
      return 'max-width: ' + width + '; max-height: ' + height + ';';
    })
  });
});