define('ember-cli-crudities/utils/conditional-format', ['exports', 'ember-cli-crudities/utils/evaluator'], function (exports, _evaluator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = conditionalFormat;
  function conditionalFormat(model, conditions) {
    var prop = void 0;
    var propval = void 0;
    var priority = void 0;
    var i = void 0;

    var rv = [];

    for (prop in conditions) {
      if (conditions.hasOwnProperty(prop)) {
        for (i = 0; i < conditions[prop].length; i++) {
          propval = model.get(conditions[prop][i].property_path);
          priority = conditions[prop][i].priority;
          if ((0, _evaluator.default)(conditions[prop][i], propval)) {
            rv.push({ value: prop, priority: priority ? priority : -1 });
          }
        }
      }
    }

    if (rv.length > 0) {
      rv.sort(function (a, b) {
        return b.priority - a.priority;
      });
      return rv[0].value;
    }

    return '';
  }
});