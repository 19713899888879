define('ember-cli-dynamic-model/services/model-loader', ['exports', 'ember-network/fetch', 'ember-inflector'], function (exports, _fetch, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    loaded_js: new Ember.Object(),
    loading: new Ember.A(),
    loaded_meta: {},
    app_data: undefined,

    fetch_app_meta: function fetch_app_meta() {
      var _this = this;

      var appData = this.get('app_data');
      if (appData !== undefined) {
        return Ember.RSVP.resolve(appData);
      }

      var appConfig = Ember.getOwner(this).resolveRegistration('config:environment');
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _fetch.default)(appConfig.APP.API_HOST + '/' + appConfig.APP.API_NAMESPACE + '/?format=json', {
          method: 'OPTIONS'
        }).then(function (response) {
          response.json().then(function (json) {
            _this.set('app_data', json);
            resolve(new Ember.Object(json));
          }).catch(function (err) {
            reject(err);
          });
        });
      });
      return promise;
    },
    handle_meta: function handle_meta(data_obj, app, model) {
      var _this2 = this;

      var fieldsets = [];
      data_obj.fieldsets.forEach(function (fieldset) {
        fieldsets.push(_this2.merge_fields_fieldset(data_obj.fields, fieldset));
      });
      data_obj.fieldsets = fieldsets;
      this.get('loaded_meta')[app + '/' + model] = new Ember.Object(data_obj);
      return data_obj;
    },
    do_get_model_meta: function do_get_model_meta(app, model) {
      var _this3 = this;

      var appConfig = Ember.getOwner(this).resolveRegistration('config:environment');
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _fetch.default)(appConfig.APP.API_HOST + '/' + appConfig.APP.API_NAMESPACE + '/' + app + '/' + model + '/?format=json', {
          method: 'OPTIONS'
        }).then(function (response) {
          response.json().then(function (json) {
            var data_obj = Ember.Object.create(json);
            resolve(_this3.handle_meta(data_obj, app, model));
          }).catch(function (err) {
            reject(err);
          });
        }).catch(function (error) {
          reject(error);
        });
      });
      this.get('loaded_meta')[app + '/' + model] = promise;
      return promise;
    },
    get_model_meta: function get_model_meta(app, model) {
      var loaded_meta = this.get('loaded_meta');
      if (loaded_meta.hasOwnProperty(app + '/' + model)) {
        var data = loaded_meta[app + '/' + model];
        if (data.isFulfilled === true || data.isFulfilled === false) {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            data.then(function (json) {
              resolve(json);
            }).catch(function (err) {
              reject(err);
            });
          });
        }
        return new Ember.RSVP.Promise(function (resolve) {
          resolve(loaded_meta[app + '/' + model]);
        });
      }
      return this.do_get_model_meta(app, model);
    },
    merge_fields_fieldset: function merge_fields_fieldset(fields, fieldset) {
      var _this4 = this;

      var rv = Ember.Object.create(_extends({}, fieldset, {
        fields: Ember.A()
      }));

      if (fieldset.fields === undefined) {
        return rv;
      }

      fieldset.fields.forEach(function (field) {
        var meta_field = fields.findBy('name', field.name);
        if (meta_field !== undefined) {
          ['name', 'widget', 'label', 'readonly', 'required', 'translated'].forEach(function (item) {
            if (field[item] === undefined && meta_field[item] !== undefined) {
              field[item] = meta_field[item];
            }
          });
          ['extra', 'validations'].forEach(function (item) {
            if (field[item] === undefined) {
              field[item] = {};
            }
            Ember.set(field, item, Ember.Object.create(field[item]));

            if (meta_field[item] !== undefined) {
              for (var k in meta_field[item]) {
                if (meta_field[item].hasOwnProperty(k) && field[item].get(k) === undefined) {
                  field[item].set(k, meta_field[item][k]);
                }
              }
            }
          });
        }
        if (field.widget === 'fieldset') {
          field.fields = _this4.merge_fields_fieldset(fields, field).fields;
        }
        if (field.widget === 'tabset') {
          field.tabs.forEach(function (tab) {
            tab.fields = _this4.merge_fields_fieldset(fields, tab).fields;
          });
        }
        rv.fields.push(Ember.Object.create(field));
      });
      return rv;
    },
    get_model_js: function get_model_js(app, singular, plural, resolve, reject) {
      var _this5 = this;

      var appConfig = Ember.getOwner(this).resolveRegistration('config:environment');
      var modulePrefix = appConfig.modulePrefix;
      var App = Ember.getOwner(this);
      var file_name = app + '/' + plural;
      var loading = void 0;
      if (App.hasRegistration('model:' + app + '/' + singular) === false) {
        Ember.$.getScript('/models/' + file_name + '.js').done(function () {
          var model_module = window.require(modulePrefix + '/models/' + app + '/' + singular)['default'];
          var registered = false;
          while (!registered) {
            try {
              App.register('model:' + app + '/' + singular, model_module, { singleton: false });
              registered = true;
            } catch (err) {
              if (err.message && err.message.match(/re-register/)) {
                registered = true;
              } else {
                /* eslint-disable no-console */
                console.error(err);
                /* was too fast */
                /* eslint-enable no-console */
              }
            }
          }
          loading = _this5.get('loading');
          if (loading.indexOf(app + '/' + singular) !== -1) {
            _this5.set('loading', loading.filter(function (item) {
              item !== app + '/' + singular + ';';
            }));
          }
          resolve();
        }).fail(function (err) {
          reject(err);
        });
      } else {
        loading = this.get('loading');
        if (loading.indexOf(app + '/' + singular) !== -1) {
          this.set('loading', loading.filter(function (item) {
            item !== app + '/' + singular + ';';
          }));
        }
        resolve();
      }
    },
    ensure_model: function ensure_model(app, singular, plural, fetchMeta) {
      var _this6 = this;

      var loaded_js = this.get('loaded_js');
      if (fetchMeta === undefined) {
        fetchMeta = true;
      }
      if (loaded_js.hasOwnProperty(app + '/' + singular)) {
        return new Ember.RSVP.Promise(function (resolve) {
          var rv = { app: app, name: singular };
          if (fetchMeta) {
            rv['meta'] = loaded_js[app + '/' + singular];
          }
          resolve(rv);
        });
      }
      var promises = [];
      if (plural === undefined) {
        plural = _emberInflector.default.inflector.pluralize(singular);
      }

      return new Ember.RSVP.Promise(function (top_resolve, top_reject) {
        if (fetchMeta) {
          _this6.get_model_meta(app, plural).then(function (json) {
            promises.push(new Ember.RSVP.Promise(function (resolve, reject) {
              _this6.get_model_js(app, singular, plural, resolve, reject);
            }).then(function () {
              _this6.get('loaded_js')[app + '/' + singular] = json;
            }));
            if (json.needs) {
              json.needs.forEach(function (child_model) {
                var loading = _this6.get('loading');
                if (loading.indexOf(child_model) === -1) {
                  loading.pushObject(child_model);
                  promises.push(_this6.ensure_model(child_model.app, child_model.singular, child_model.plural, fetchMeta));
                }
              });
            }
            Ember.RSVP.allSettled(promises).then(function () {
              top_resolve({ app: app, name: singular, meta: json });
            }).catch(function (error) {
              top_reject(error);
            });
          }).catch(function (err) {
            top_reject(err);
          });
        } else {
          _this6.get_model_js(app, singular, plural, top_resolve, top_reject);
        }
      });
    }
  });
});