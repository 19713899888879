define('liquid-wormhole/services/liquid-wormhole', ['exports', 'ember', 'perf-primitives/hash-map', 'ember-getowner-polyfill'], function (exports, _ember, _perfPrimitivesHashMap, _emberGetownerPolyfill) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.destination = new _perfPrimitivesHashMap['default']();
    },

    appendWormhole: function appendWormhole(wormhole) {
      var destinationName = arguments.length <= 1 || arguments[1] === undefined ? 'default' : arguments[1];

      var destination = this.destination.get(destinationName);

      if (destination === undefined) {
        if (destinationName === 'default') {
          destination = this.addDefaultDestination();
        } else {
          throw new Error('Liquid Wormhole destination does not exist');
        }
      }

      destination.appendWormhole(wormhole);
    },

    removeWormhole: function removeWormhole(wormhole) {
      var destinationName = arguments.length <= 1 || arguments[1] === undefined ? 'default' : arguments[1];

      var destination = this.destination.get(destinationName);

      if (destination === undefined) {
        throw new Error('Liquid Wormhole destination does not exist');
      }

      destination.removeWormhole(wormhole);
    },

    registerDestination: function registerDestination(destinationName, destination) {
      if (this.destination.get(destinationName)) {
        throw new Error('Liquid Wormhole destination \'' + destinationName + '\' already created');
      }
      this.destination.set(destinationName, destination);
    },

    willDestroy: function willDestroy() {
      this.removeDefaultDestination();
    },

    addDefaultDestination: function addDefaultDestination() {
      var instance = getOwner ? getOwner(this) : (0, _emberGetownerPolyfill['default'])(this);
      var destination = instance.lookup('component:liquid-destination');
      destination.set('classNames', ['liquid-destination', 'default-liquid-destination']);

      if (instance.rootElement) {
        destination.appendTo(instance.rootElement);
      } else if (_ember['default'].$('.ember-application').length > 0) {
        destination.appendTo(_ember['default'].$('.ember-application')[0]);
      } else {
        destination.appendTo(document);
      }

      this.defaultDestination = destination;

      return destination;
    },

    removeDefaultDestination: function removeDefaultDestination() {
      if (this.defaultDestination) {
        this.defaultDestination.destroy();
      }
    }

  });
});