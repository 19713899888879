define('ember-cli-crudities/components/form-action', ['exports', 'ember-cli-crudities/templates/components/form-action'], function (exports, _formAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _formAction.default,
    status: 'success',
    hasError: false,
    validate: function validate() {
      return Ember.RSVP.resolve(true);
    }
  });
});