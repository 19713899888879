define('ember-cli-crudities/helpers/records-aggregate', ['exports', 'ember-cli-crudities/utils/records-aggregates'], function (exports, _recordsAggregates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.recordsAggregate = recordsAggregate;
  function recordsAggregate(params /*, hash*/) {
    var _params$ = params[0],
        type = _params$.type,
        property_path = _params$.property_path,
        group_by = _params$.group_by;

    var records = params[1];

    var rv = (0, _recordsAggregates.default)(records, type, property_path, group_by);

    if (group_by) {
      var rrv = [];
      for (var prop in rv) {
        if (rv.hasOwnProperty(prop)) {
          rrv.push(prop + ': ' + rv[prop][0]);
        }
      }
      return rrv;
    }

    return [rv['_']];
  }

  exports.default = Ember.Helper.helper(recordsAggregate);
});