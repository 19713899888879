define('ember-cli-crudities/mixins/with-condition', ['exports', 'ember-cli-crudities/mixins/condition-evaluator'], function (exports, _conditionEvaluator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_conditionEvaluator.default, {
    observers: [],
    toWatch: [],

    _update_prop: function _update_prop(observer, condition, prop) {
      if (Ember.isEmpty(observer) || Ember.isEmpty(condition)) {
        return;
      }
      var value = this.get(observer);
      var rv = this.evaluate(this.get(condition), value);
      this.set(prop, rv);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super();
      var observers = this.get('observers');
      this.get('toWatch').forEach(function (toWatchItem) {
        var toWatch = _this.get(toWatchItem[0] + '.property_path');
        if (!Ember.isEmpty(toWatch)) {
          var observer = 'model.' + toWatch;
          var fn_name = '_update_' + toWatchItem[1];
          _this[fn_name] = _this._update_prop.bind(_this, observer, toWatchItem[0], toWatchItem[1]);
          _this.addObserver(observer, _this[fn_name]);
          observers.push([observer, fn_name]);
          _this[fn_name]();
        }
      });
    },
    willDestroy: function willDestroy() {
      var _this2 = this;

      this._super();
      this.get('observers').forEach(function (item) {
        _this2.removeObserver(item[0], _this2[item[1]]);
      });
    }
  });
});