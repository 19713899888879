define('ember-cli-crudities/components/tomany-aggregate', ['exports', 'ember-cli-crudities/utils/records-aggregates', 'ember-cli-crudities/templates/components/tomany-aggregate'], function (exports, _recordsAggregates, _tomanyAggregate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _tomanyAggregate.default,
    classNames: ['aggregate'],

    observed: [],
    values: {},

    doUpdate: function doUpdate() {
      var records = this.get('records');
      var type = this.get('type');
      var propertyPath = this.get('propertyPath');
      var groupBy = this.get('groupBy');
      if (records && type && propertyPath) {
        var values = (0, _recordsAggregates.default)(records, type, propertyPath, groupBy);
        this.set('values', values);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super();
      var propertyPath = this.get('propertyPath');
      this.set('observed', 'records.@each.' + propertyPath);
      Ember.addObserver(this, 'records.@each.' + propertyPath, this, 'doUpdate');
    },
    willDestroy: function willDestroy() {
      this._super();
      Ember.removeObserver(this, this.get('observed'), this, 'doUpdate');
    },


    onInit: Ember.on('init', function () {
      this.doUpdate();
    })
  });
});