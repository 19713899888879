define('ember-cli-crudities/components/form-textarea', ['exports', 'ember-cli-crudities/templates/components/form-textarea', 'ember-cli-crudities/mixins/boundvalue'], function (exports, _formTextarea, _boundvalue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_boundvalue.default, {
    layout: _formTextarea.default,
    tagName: undefined,
    type: 'textarea',

    _rows: 4,
    rows: Ember.computed.or('extra.rows', '_rows'),

    actions: {
      validate: function validate(parent) {
        parent.validate();
      }
    }
  });
});