define('ember-cli-crudities/components/form-tomany-slide', ['exports', 'ember-cli-crudities/components/tomany-base', 'ember-cli-crudities/templates/components/form-tomany-slide'], function (exports, _tomanyBase, _formTomanySlide) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _tomanyBase.default.extend({
    layout: _formTomanySlide.default,

    currentIndex: 0,
    indexPlusOne: computed('currentIndex', 'related.length', 'related', function () {
      var related_length = this.get('related.length');
      if (related_length) {
        var currentIndex = this.get('currentIndex');
        return currentIndex + 1;
      }
      return 0;
    }),
    record: computed('related.length', 'currentIndex', 'related', function () {
      var index = this.get('currentIndex');
      var related = this.get('related');
      if (related && index < related.get('length')) {
        return related.objectAt(index);
      }
      return null;
    }),

    direction: 'toLeft',

    actions: {
      previous: function previous() {
        this.set('direction', 'toRight');
        var index = this.get('currentIndex');
        if (index > 0) {
          this.set('currentIndex', parseInt(index) - 1);
        } else {
          var length = this.get('related.length');
          if (length > 0) {
            this.set('currentIndex', length - 1);
          }
        }
      },
      next: function next() {
        this.set('direction', 'toLeft');
        var index = parseInt(this.get('currentIndex'));
        var length = this.get('related.length');
        if (index < length - 1) {
          this.set('currentIndex', index + 1);
        } else if (length > 0) {
          this.set('currentIndex', 0);
        }
      },
      addRelated: function addRelated() {
        this._super();
        this.set('currentIndex', parseInt(this.get('related.length') - 1));
      },
      deleteRelated: function deleteRelated(record) {
        this.set('currentIndex', 0);
        this._super(record);
      }
    }
  });
});