define('ember-cli-crudities/components/form-duration', ['exports', 'ember-cli-crudities/templates/components/form-duration', 'ember-cli-crudities/utils/duration'], function (exports, _formDuration, _duration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _formDuration.default,
    _debounce: 500,
    _value: null,
    debounce: Ember.computed.or('field.extra.debounce', '_debounce'),

    setValue: function setValue() {
      var value = this.get('_value');
      this.set('value', (0, _duration.str_to_seconds)(value, this.get('extra.withSeconds')));
    },


    displayValue: Ember.computed('value', {
      get: function get() {
        var rv = (0, _duration.seconds_to_str)(this.get('value'), this.get('extra.withSeconds'));
        return rv;
      },
      set: function set(key, value) {
        this.set('_value', value);
        Ember.run.debounce(this, this.setValue, this.get('debounce'));
        return value;
      }
    }),

    validate: function validate(widget) {
      return widget.validate();
    }
  });
});