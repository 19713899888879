define('smoke-and-mirrors/-private/radar/models/list-radar', ['exports', 'smoke-and-mirrors/-private/radar/models/radar', 'smoke-and-mirrors/-private/radar/models/list-satellite', 'ember'], function (exports, _smokeAndMirrorsPrivateRadarModelsRadar, _smokeAndMirrorsPrivateRadarModelsListSatellite, _ember) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

  var guidFor = _ember['default'].guidFor;

  var ListRadar = (function (_Radar) {
    _inherits(ListRadar, _Radar);

    function ListRadar() {
      _get(Object.getPrototypeOf(ListRadar.prototype), 'constructor', this).apply(this, arguments);
    }

    _createClass(ListRadar, [{
      key: 'register',
      value: function register(component) {
        var sat = _smokeAndMirrorsPrivateRadarModelsListSatellite['default'].create({
          component: component,
          dimensions: undefined,
          element: component.element,
          key: guidFor(component),
          radar: this,
          scalar: undefined
        }, this);

        this._push(sat);

        return sat;
      }
    }, {
      key: '_resize',
      value: function _resize() {
        if (this.alwaysRemeasure) {
          this.rebuild();
        }

        for (var i = 0; i < this.length; i++) {
          var satellite = this.satellites[i];
          var change = satellite.resize();

          if (change) {
            ListRadar.adjustSatelliteList(satellite, change);
          }
        }
      }
    }, {
      key: '_adjust',
      value: function _adjust(satellite, change) {
        ListRadar.adjustSatelliteList(satellite, change);
      }
    }], [{
      key: 'adjustSatelliteList',
      value: function adjustSatelliteList(satellite, change) {
        while (satellite = satellite.next()) {
          satellite.shift(change.dY, change.dX);
        }
      }
    }]);

    return ListRadar;
  })(_smokeAndMirrorsPrivateRadarModelsRadar['default']);

  exports['default'] = ListRadar;
});