define('ember-cli-crudities/components/form-checkbox', ['exports', 'ember-cli-crudities/components/form-input'], function (exports, _formInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formInput.default.extend({
    type: 'checkbox',
    _checkbox_class: 'checkbox-primary',
    checkboxClass: Ember.computed.or('extra.checkboxClass', '_checkbox_class')
  });
});