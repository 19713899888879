define('ember-cli-crudities/components/form-json-fieldset', ['exports', 'ember-cli-crudities/templates/components/form-json-fieldset'], function (exports, _formJsonFieldset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _formJsonFieldset.default,
    dynField: Ember.computed('model', 'model.isFulfilled', 'field', 'field.extra', 'field.extra.data', 'field.extra.data.@each', 'field.fields', 'field.fields.@each', function () {
      var field = new Ember.Object(this.get('field'));
      if (Ember.isEmpty(field.get('fields'))) {
        var fields = this.get('model.' + field.get('extra.data'));
        field.set('fields', fields);
      }
      field.set('name', undefined);
      return field;
    })
  });
});