define('ember-cli-crudities/components/app-dashboard', ['exports', 'ember-cli-crudities/templates/components/app-dashboard', 'ember-cli-crudities/mixins/configurable-loader'], function (exports, _appDashboard, _configurableLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_configurableLoader.default, {
    layout: _appDashboard.default,
    modelLoader: Ember.inject.service(),
    model: new Ember.A(),
    willRender: function willRender() {
      var _this = this;

      if (this.attrs.model !== undefined) {
        return;
      }
      var modelLoader = this.get('modelLoader');
      modelLoader.fetch_app_meta().then(function (metadata) {
        var rv = metadata.applications;
        _this.set('model', rv);
      });
    }
  });
});