define('ember-cli-crudities/helpers/action-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.actionUrl = actionUrl;
  function actionUrl(params /*, hash*/) {
    return params[0].replace(':id', params[1]);
  }

  exports.default = Ember.Helper.helper(actionUrl);
});