define('ember-cli-crudities/utils/evaluator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = evaluate;
  function evaluate(condition, value) {
    var rv = false;
    if (!condition) {
      return rv;
    }
    switch (condition.operator) {
      case 'eq':
        rv = value === condition.value;
        break;
      case 'not-eq':
        rv = value !== condition.value;
        break;
      case 'not':
        rv = !value;
        break;
      case 'and':
        rv = value && condition.value;
        break;
      case 'or':
        rv = value || condition.value;
        break;
      case 'xor':
        rv = value && !condition.value || !value && condition.value;
        break;
      case 'gt':
        rv = value > condition.value;
        break;
      case 'gte':
        rv = value >= condition.value;
        break;
      case 'lt':
        rv = value < condition.value;
        break;
      case 'lte':
        rv = value <= condition.value;
        break;
      case 'is-array':
        rv = Ember.isArray(value);
        break;
      case 'is-equal':
        rv = Ember.isEqual(value, condition.value);
        break;
      case 'in':
        rv = condition.value.indexOf(value) > -1;
        break;
      case 'not-in':
        rv = condition.value.indexOf(value) === -1;
        break;
      case 'empty':
        rv = Ember.isEmpty(value);
        break;
      case 'not-empty':
        rv = !Ember.isEmpty(value);
        break;
      case 'not-null':
        rv = value !== null;
        break;
    }

    if (condition.type === 'unless') {
      rv = !rv;
    }

    return rv;
  }
});