define('ember-cli-crudities/mixins/boundvalue', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    original: undefined,

    translated: Ember.computed('field', 'field.translated', function () {
      return this.get('field.translated');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('original', this.get('model.' + this.get('property')));
    },


    onValueChange: Ember.observer('value', function () {
      if (this.attrs.onChange !== undefined) {
        var original = this.get('original');
        try {
          original = original.get('id');
        } catch (e) {
          /* ok */
        }
        var value = this.get('value');
        try {
          value = value.get('id');
        } catch (e) {
          /* ok */
        }
        if (value !== original || value === undefined) {
          this.onChange(this.get('property'), this.get('value'));
        }
      }
    })
  });
});