define('liquid-wormhole/components/liquid-append', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get('replaceNodes')) {
        var nodes = this.get('nodes');

        this.$().children().remove();
        this.$().append(nodes);
      }
    },

    didInsertElement: function didInsertElement() {
      var notify = this.get('notify');
      var nodes = this.get('nodes');

      if (notify && notify.willAppendNodes) {
        notify.willAppendNodes(this.element);
      }

      this.$().append(nodes);

      if (notify && notify.didAppendNodes) {
        notify.didAppendNodes(this.element);
      }
    }
  });
});