define('ember-cli-crudities/components/form-generic-foreignkey', ['exports', 'ember-cli-crudities/templates/components/form-generic-foreignkey', 'ember-cli-crudities/mixins/with-custom-actions'], function (exports, _formGenericForeignkey, _withCustomActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend(_withCustomActions.default, {
    layout: _formGenericForeignkey.default,
    type: 'foreignkey',

    displayValue: null,
    linkApp: null,
    linkModel: null,
    allowedActions: [],

    contentType: Ember.computed('value', 'value.content_type', function () {
      var _this = this;

      if (Ember.isEmpty(this.get('value'))) {
        return null;
      }
      var ct_id = this.get('value.content_type');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('store').findRecord('contenttypes/contenttype', ct_id).then(function (rv) {
          resolve(rv);
        }, reject);
      });
    }),

    _do_get_related_model: function _do_get_related_model(ct, resolve, reject) {
      var _this2 = this;

      this.get('modelLoader').ensure_model(ct.get('app_label'), ct.get('model')).then(function (meta) {
        if (!_this2.get('isDestroyed') && !_this2.get('isDestroying')) {
          var actions = meta.meta.get('custom_actions');
          var allowed = _this2.get('extra.allowedActions');
          if (Ember.isEmpty(actions)) {
            _this2.set('allowedActions', []);
          } else if (Ember.isEmpty(allowed) || allowed === undefined) {
            _this2.set('allowedActions', []);
          } else {
            var allowedActions = actions.filter(function (action) {
              return allowed.some(function (item) {
                if (action.slug === undefined) {
                  return false;
                }
                var r = new RegExp(item);
                return action.slug.match(r);
              });
            });
            _this2.set('allowedActions', allowedActions);
          }
          _this2.set('linkApp', ct.get('app_label'));
          _this2.set('linkModel', ct.get('model'));
        }
        resolve(ct);
      }, reject);
    },


    relatedModel: Ember.computed('contentType', function () {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var content_type = _this3.get('contentType');
        if (content_type.hasOwnProperty('isFulfilled') || content_type.then) {
          content_type.then(function (ct) {
            _this3._do_get_related_model(ct, resolve, reject);
          }, reject);
        } else {
          _this3._do_get_related_model(content_type, resolve, reject);
        }
      });
    }),

    onValueChange: Ember.observer('value', 'relatedModel', 'value.id', function () {
      this.setDisplayVal();
    }),

    valueId: Ember.computed('value', 'value.id', function () {
      if (Ember.isEmpty(this.get('value'))) {
        return null;
      }
      return this.get('value.id');
    }),

    changeRelatedModel: function changeRelatedModel(value) {
      if (value === 'contentType') {
        return;
      }
      this.set('model.' + this.get('property'), {
        content_type: Ember.get(value, 'id'),
        id: this.get('value.id')
      });
    },
    changeRelatedId: function changeRelatedId(value) {
      if (value === 'displayValue') {
        return;
      }
      this.set('model.' + this.get('property'), {
        content_type: this.get('value.content_type'),
        id: Ember.get(value, 'id')
      });
    },
    setDisplayVal: function setDisplayVal() {
      var _this4 = this;

      var valueId = this.get('valueId');
      if (Ember.isEmpty(valueId)) {
        return null;
      }
      var ct_prom = this.get('relatedModel');
      if (Ember.isEmpty(ct_prom)) {
        return null;
      }
      ct_prom.then(function (ct) {
        _this4.get('store').findRecord(ct.get('app_label') + '/' + ct.get('model'), valueId).then(function (rec) {
          if (!_this4.get('isDestroyed') && !_this4.get('isDestryoing')) {
            _this4.set('displayValue', rec);
          }
        });
      });
    },


    extraForContentType: Ember.computed('extra', function () {
      var extra = this.get('extra');
      return _extends({}, extra, {
        related_model: 'contenttypes/contenttype'
      });
    }),

    _do_set_extra_with_content_type: function _do_set_extra_with_content_type(ct) {
      var extra = this.get('extra');
      var rv = _extends({}, extra, {
        related_model: ct.get('app_label') + '/' + ct.get('model')
      });
      if (!this.get('isDestroyed') && !this.get('isDestroiyng')) {
        this.set('extraWithRelated', rv);
      }
    },
    setExtraWithContentType: function setExtraWithContentType() {
      var _this5 = this;

      var content_type = this.get('contentType');
      if (Ember.isEmpty(content_type)) {
        return;
      }
      if (content_type.hasOwnProperty('isFulfilled') || content_type.then) {
        content_type.then(function (ct) {
          _this5._do_set_extra_with_content_type(ct);
        });
      } else {
        this._do_set_extra_with_content_type(content_type);
      }
    },


    onChangeContentType: Ember.observer('extra', 'contentType', function () {
      this.setExtraWithContentType();
    }),

    init: function init() {
      this._super();
      this.setDisplayVal();
      this.setExtraWithContentType();
    }
  });
});