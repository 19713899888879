define('smoke-and-mirrors/utils/proxied-array', ['exports', 'ember', 'smoke-and-mirrors/-private/ember/utils/key-for-item'], function (exports, _ember, _smokeAndMirrorsPrivateEmberUtilsKeyForItem) {
  exports['default'] = proxiedArray;
  var computed = _ember['default'].computed;
  var ArrayProxy = _ember['default'].ArrayProxy;
  var ObjectProxy = _ember['default'].ObjectProxy;
  var get = _ember['default'].get;

  function valueForIndex(arr, index) {
    return arr.objectAt ? arr.objectAt(index) : arr[index];
  }

  function changeIsPrepend(oldArray, newArray, keyPath) {
    var lengthDifference = get(newArray, 'length') - get(oldArray, 'length');

    // if either array is empty or the new array is not longer, do not treat as prepend
    if (!get(newArray, 'length') || !get(oldArray, 'length') || lengthDifference <= 0) {
      return false;
    }

    // if the keys at the correct indexes are the same, this is a prepend
    var oldInitialItem = get(valueForIndex(oldArray, 0), 'content');
    var oldInitialKey = (0, _smokeAndMirrorsPrivateEmberUtilsKeyForItem['default'])(oldInitialItem, keyPath, 0);
    var newInitialItem = valueForIndex(newArray, lengthDifference);
    var newInitialKey = (0, _smokeAndMirrorsPrivateEmberUtilsKeyForItem['default'])(newInitialItem, keyPath, lengthDifference);

    return oldInitialKey === newInitialKey;
  }

  function proxiedArray(arrayKey) {
    var _this = this;

    var keyPath = arguments.length <= 1 || arguments[1] === undefined ? '@identity' : arguments[1];

    // create the value cache for the array
    var outbound = ArrayProxy.create({ content: _ember['default'].A() });

    // create the computed args array
    var args = [];

    args.push(arrayKey.indexOf('.[]') !== -1 ? arrayKey : arrayKey + '.[]');

    var fn = function fn() {
      var inbound = _this.get(arrayKey);

      if (!inbound || !get(inbound, 'length')) {
        outbound.clear();
        return outbound;
      }
      var newLength = undefined;
      var newObjects = _ember['default'].A();
      var diff = undefined;

      outbound.beginPropertyChanges();

      // handle additions to the beginning of the array
      if (changeIsPrepend(outbound, inbound, keyPath)) {
        newLength = get(inbound, 'length');
        diff = newLength - outbound.get('length');
        for (var i = 0; i < diff; i++) {
          newObjects.push(ObjectProxy.create({ content: inbound[i] }));
        }
        if (newObjects.length) {
          outbound.replace(0, 0, newObjects);
        }

        // handle additions and inline changes
      } else {
          inbound.forEach(function (item, index) {
            var proxiedObject = outbound.objectAt(index);

            if (proxiedObject) {
              proxiedObject.set('content', item);
            } else {
              newObjects.push(ObjectProxy.create({ content: item }));
            }
          });

          if (newObjects.length) {
            outbound.pushObjects(newObjects);
          }
        }

      newLength = inbound ? get(inbound, 'length') : 0;

      if (newLength < outbound.get('length')) {
        diff = outbound.get('length') - newLength;
        outbound.removeAt(newLength, diff);
      }

      outbound.endPropertyChanges();
      outbound.notifyPropertyChange('length');
      return outbound;
    };

    args.push(fn);
    return computed.apply(this, args);
  }
});