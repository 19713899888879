define('smoke-and-mirrors/components/vertical-collection/component', ['exports', 'ember', 'smoke-and-mirrors/components/vertical-collection/template', 'smoke-and-mirrors/utils/get-tag-descendant', 'smoke-and-mirrors/utils/proxied-array', 'smoke-and-mirrors/-private/radar/models/list-radar', 'smoke-and-mirrors/-private/ember/utils/identity', 'smoke-and-mirrors/-private/scheduler'], function (exports, _ember, _smokeAndMirrorsComponentsVerticalCollectionTemplate, _smokeAndMirrorsUtilsGetTagDescendant, _smokeAndMirrorsUtilsProxiedArray, _smokeAndMirrorsPrivateRadarModelsListRadar, _smokeAndMirrorsPrivateEmberUtilsIdentity, _smokeAndMirrorsPrivateScheduler) {
  var K = _ember['default'].K;
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var Logger = _ember['default'].Logger;
  var isBlank = _ember['default'].isBlank;
  var getOwner = _ember['default'].getOwner;

  function valueForIndex(arr, index) {
    return arr.objectAt ? arr.objectAt(index) : arr[index];
  }

  function getContent(obj, isProxied) {
    var key = isProxied ? 'content.content' : 'content';

    return get(obj, key);
  }

  var VerticalCollection = Component.extend({
    /*
     * Defaults to `vertical-collection`.
     *
     * If itemTagName is blank or null, the `vertical-collection` will [tag match](../addon/utils/get-tag-descendant.js)
     * with the `vertical-item`.
     */
    tagName: 'vertical-collection',
    layout: _smokeAndMirrorsComponentsVerticalCollectionTemplate['default'],

    content: computed.alias('items'),
    items: undefined,

    // –––––––––––––– Required Settings

    /*
     * This height is used to give the `vertical-item`s height prior to
     * it's content being rendered.
     *
     * This height is replaced with the actual rendered height once content
     * is rendered for the first time.
     */
    defaultHeight: 75,
    alwaysRemeasure: false,
    alwaysUseDefaultHeight: computed.not('alwaysRemeasure'),

    /*
     * Cached value used once default height is
     * calculated firmly
     */
    _defaultHeight: null,

    // –––––––––––––– Optional Settings

    /*
     * Classes to add to the `vertical-item`
     */
    itemClassNames: '',

    // –––––––––––––– Optional Settings
    /*
     * A jQuery selector string that will select the element from
     * which to calculate the viewable height and needed offsets.
     *
     * This element will also have the `scroll` event handler added to it.
     *
     * Usually this element will be the component's immediate parent element,
     * if so, you can leave this null.
     *
     * Set this to "body" to scroll the entire web page.
     */
    containerSelector: null,

    /*
     * Used if you want to explicitly set the tagName of collection's items
     */
    itemTagName: '',
    key: '@identity',

    // –––––––––––––– Performance Tuning
    /*
     * how much extra room to keep visible and invisible on
     * either side of the viewport.
     *
     * This used to be two separate values (invisibleBuffer/visibleBuffer)
     * but these values have been unified to ease a future transition in
     * the internal mechanics of the collection to utilize DOM recycling.
     */
    bufferSize: 0.25,

    /*
     * useContentProxy
     */
    useContentProxy: false,

    // –––––––––––––– Initial State
    /*
     *  If set, this will be used to set
     *  the scroll position at which the
     *  component initially renders.
     */
    scrollPosition: 0,

    /*
     * If set, if scrollPosition is empty
     * at initialization, the component will
     * render starting at the bottom.
     */
    renderFromLast: false,
    __isInitializingFromLast: false,

    /*
     * If set, all items will initially be revealed
     * so that their dimensions can be correctly
     * determined
     */
    renderAllInitially: false,
    _isFirstRender: true,

    /*
     * If set, upon initialization the scroll
     * position will be set such that the item
     * with the provided id is at the top left
     * on screen.
     *
     * If the item cannot be found, scrollTop
     * is set to 0.
     */
    idForFirstItem: null,

    // –––––––––––––– Actions
    /*
     * Specify an action to fire when the last item is reached.
     *
     * This action will only fire once per unique last item, and
     * is fired the moment the last element is visible, it does
     * not need to be on screen yet.
     *
     * It will include the index and content of the last item,
     * as well as a promise.
     *
     * ```
     * {
     *  index: 0,
     *  item : {},
     *  promise: fn
     * }
     * ```
     *
     * The promise should be resolved once any loading is complete, or
     * rejected if loading has failed.
     *
     * If `loadingComponentClass` is defined, it will be inserted above existing content.
     *
     * Rejecting the promise leaves the loadingComponent in place for 5s and set's
     * it's `loadingFailed` property to true.
     *
     */
    firstReached: null,

    /*
     * Specify an action to fire when the first item is reached.
     *
     * This action will only fire once per unique first item, and
     * is fired the moment the first element is visible, it does
     * not need to be on screen yet.
     *
     * It will include the index and content of the first item
     * as well as a promise.
     *
     * ```
     * {
     *  index: 0,
     *  item : {},
     *  promise: fn
     * }
     * ```
     *
     * The promise should be resolved once any loading is complete, or
     * rejected if loading has failed.
     *
     * If `loadingViewClass` is defined, it will be inserted above existing content.
     *
     * Rejecting the promise leaves the loadingView in place for 5s and set's
     * it's `loadingFailed` property to true.
     *
     */
    lastReached: null,

    /*
     * Specify an action to fire when the first on-screen item
     * changes.
     *
     * It includes the index and content of the item now visible.
     */
    firstVisibleChanged: null,

    /*
     * Specify an action to fire when the last on-screen item
     * changes.
     *
     * It includes the index and content of the item now visible.
     */
    lastVisibleChanged: null,

    _content: null,

    // –––––––––––––– Private Internals
    _firstVisibleIndex: 0,

    /*
     * a cached element reference to the container "viewport" element
     * this is known as the "telescope" within the Radar class.
     */
    _container: null,

    /*
     * Set this to false to prevent rendering entirely.
     * Useful for situations in which rendering is
     * expensive enough that it interferes with a
     * transition animation.
     *
     * In such cases, set this to false, and switch it
     * to true once animation has completed.
     */
    shouldRender: true,

    /*
     * forward is true, backwards is false
     */
    _scrollIsForward: 0,
    radar: null,
    _nextUpdate: null,
    _nextSync: null,
    _nextScrollSync: null,
    _isPrepending: false,
    _children: null,

    keyForItem: function keyForItem(item, index) {
      var key = undefined;
      var keyPath = this.get('key');

      switch (keyPath) {
        case '@index':
          // allow 0 index
          if (!index && index !== 0) {
            throw new Error('No index was supplied to keyForItem');
          }
          key = index;
          break;
        case '@identity':
          key = (0, _smokeAndMirrorsPrivateEmberUtilsIdentity['default'])(item);
          break;
        default:
          if (keyPath) {
            key = get(item, keyPath);
          } else {
            key = (0, _smokeAndMirrorsPrivateEmberUtilsIdentity['default'])(item);
          }
      }

      if (typeof key === 'number') {
        key = String(key);
      }

      return key;
    },

    // –––––––––––––– Action Helper Functions
    canSendActions: function canSendActions(name /* context*/) {
      // don't trigger during a prepend or initial render
      if (this._isFirstRender || this._isPrepending) {
        return false;
      }

      if (name === 'firstReached') {
        if (this._scrollIsForward) {
          return false;
        }
      }

      return !(name === 'lastReached' && !this._scrollIsForward);
    },

    prepareActionContext: function prepareActionContext(name, context) {
      var isProxied = this.get('useContentProxy');

      if (name === 'didMountCollection') {
        if (context.firstVisible.item) {
          context.firstVisible.item = getContent(context.firstVisible.item, isProxied);
        }

        if (context.lastVisible.item) {
          context.lastVisible.item = getContent(context.lastVisible.item, isProxied);
        }

        return context;
      }

      context.item = getContent(context.item, isProxied);
      return !context.item ? false : context;
    },

    keyForContext: function keyForContext(context) {
      return this.keyForItem(context.item, context.index);
    },

    __smActionCache: null,
    sendActionOnce: function sendActionOnce(name, context) {
      var _this = this;

      if (!this.canSendActions(name, context)) {
        return;
      }

      context = this.prepareActionContext(name, context);
      if (!context) {
        return;
      }

      var contextCache = this.__smActionCache;

      if (contextCache.hasOwnProperty(name)) {
        var contextKey = this.keyForContext(context);

        if (contextCache[name] === contextKey) {
          return;
        }
        contextCache[name] = contextKey;
      }

      // this MUST be async or glimmer will freak
      _smokeAndMirrorsPrivateScheduler['default'].schedule('affect', function () {
        setTimeout(function () {
          _this.sendAction(name, context, K);
        });
      });
    },

    /*
     Binary search for finding the topmost visible view.
     This is not the first visible item on screen, but the first
     item that will render it's content.
      @method _findFirstRenderedComponent
     @param {Number} invisibleTop The top/left of the viewport to search against
     @returns {Number} the index into childViews of the first view to render
     **/
    _findFirstRenderedComponent: function _findFirstRenderedComponent(visibleTop) {
      var childComponents = this.get('children');
      var maxIndex = childComponents.length - 1;
      var minIndex = 0;
      var midIndex = undefined;

      if (maxIndex < 0) {
        return 0;
      }

      while (maxIndex > minIndex) {
        midIndex = Math.floor((minIndex + maxIndex) / 2);

        // in case of not full-window scrolling
        var component = childComponents[midIndex];
        var componentBottom = component.satellite.geography.bottom;

        if (componentBottom > visibleTop) {
          maxIndex = midIndex - 1;
        } else {
          minIndex = midIndex + 1;
        }
      }

      return minIndex;
    },

    children: computed('_children.@each.index', function () {
      var children = this.get('_children');
      var output = new Array(get(children, 'length'));

      children.forEach(function (item) {
        var index = get(item, 'index');
        output[index] = item;
      });
      return output;
    }),

    register: function register(child) {
      this.get('_children').addObject(child);
      child.radar = this.radar;
      this._scheduleUpdate();
    },

    unregister: function unregister(child) {
      var children = this.get('_children');

      if (children) {
        children.removeObject(child);
        if (!this.get('isDestroying') && !this.get('isDestroyed')) {
          this._scheduleUpdate();
        }
      }
    },

    /*
     *
     * The big question is can we render from the bottom
     * without the bottom most item being taken off screen?
     *
     * Triggers on scroll.
     *
     * @private
     */
    _updateChildStates: function _updateChildStates() /* source */{
      // eslint: complexity
      if (!this.get('shouldRender')) {
        return;
      }

      var edges = this._edges;
      var childComponents = this.get('children');

      if (!get(childComponents, 'length')) {
        return;
      }

      if (this._isFirstRender) {
        if (this.get('renderAllInitially')) {
          childComponents.forEach(function (i) {
            i.show();
          });

          this._scheduleScrollSync();

          this._isFirstRender = false;
          return;
        }
      }

      var currentViewportBound = this.radar.skyline.top;
      var currentUpperBound = edges.visibleTop;

      if (currentUpperBound < currentViewportBound) {
        currentUpperBound = currentViewportBound;
      }

      var topComponentIndex = this._findFirstRenderedComponent(currentUpperBound);
      var bottomComponentIndex = topComponentIndex;
      var lastIndex = childComponents.length - 1;
      var topVisibleSpotted = false;
      var toCull = [];
      var toShow = [];

      while (bottomComponentIndex <= lastIndex) {
        var component = childComponents[bottomComponentIndex];
        var componentTop = component.satellite.geography.top;
        var componentBottom = component.satellite.geography.bottom;

        // end the loop if we've reached the end of components we care about
        if (componentTop > edges.visibleBottom) {
          break;
        }

        // above the upper reveal boundary
        if (componentBottom < edges.visibleTop) {
          toCull.push(component);

          // above the upper screen boundary
        } else if (componentBottom < edges.viewportTop) {
            toShow.push(component);
            if (bottomComponentIndex === 0) {
              this.sendActionOnce('firstReached', {
                item: component,
                index: bottomComponentIndex
              });
            }

            // above the lower screen boundary
          } else if (componentTop < edges.viewportBottom) {
              toShow.push(component);
              if (bottomComponentIndex === 0) {
                this.sendActionOnce('firstReached', {
                  item: component,
                  index: bottomComponentIndex
                });
              }
              if (bottomComponentIndex === lastIndex) {
                this.sendActionOnce('lastReached', {
                  item: component,
                  index: bottomComponentIndex
                });
              }

              if (!topVisibleSpotted) {
                topVisibleSpotted = true;
                this.set('_firstVisibleIndex', bottomComponentIndex);
                this.sendActionOnce('firstVisibleChanged', {
                  item: component,
                  index: bottomComponentIndex
                });
              }

              // above the lower reveal boundary (componentTop < edges.visibleBottom)
            } else {
                toShow.push(component);
                if (bottomComponentIndex === lastIndex) {
                  this.sendActionOnce('lastReached', {
                    item: component,
                    index: bottomComponentIndex
                  });
                }
              }

        bottomComponentIndex++;
      }

      this.sendActionOnce('lastVisibleChanged', {
        item: childComponents[bottomComponentIndex - 1],
        index: bottomComponentIndex - 1
      });

      toCull = toCull.concat(childComponents.slice(0, topComponentIndex)).concat(childComponents.slice(bottomComponentIndex));

      for (var j = 0; j < toCull.length; j++) {
        toCull[j].cull();
      }
      for (var k = 0; k < toShow.length; k++) {
        toShow[k].show();
      }

      this._scheduleScrollSync();

      if (this._isFirstRender) {
        this._isFirstRender = false;
        this.sendActionOnce('didMountCollection', {
          firstVisible: { item: childComponents[topComponentIndex], index: topComponentIndex },
          lastVisible: { item: childComponents[bottomComponentIndex - 1], index: bottomComponentIndex - 1 }
        });
      }
    },

    _scheduleUpdate: function _scheduleUpdate() {
      var _this2 = this;

      if (this._isPrepending) {
        return;
      }
      if (this._nextUpdate === null) {
        this._nextUpdate = _smokeAndMirrorsPrivateScheduler['default'].schedule('layout', function () {
          _this2._updateChildStates();
          _this2._nextUpdate = null;
        });
      }
    },

    _scheduleSync: function _scheduleSync() {
      var _this3 = this;

      if (this._nextSync === null) {
        this._nextSync = _smokeAndMirrorsPrivateScheduler['default'].schedule('sync', function () {
          _this3.radar.updateSkyline();
          _this3._nextSync = null;
        });
      }
    },

    _scheduleScrollSync: function _scheduleScrollSync() {
      var _this4 = this;

      if (this.get('__isInitializingFromLast')) {
        if (this._nextScrollSync === null) {
          this._nextScrollSync = _smokeAndMirrorsPrivateScheduler['default'].schedule('measure', function () {
            var last = _this4.element.lastElementChild;

            _this4.set('__isInitializingFromLast', false);
            if (last) {
              last.scrollIntoView(false);
            }

            _this4._nextScrollSync = null;
          });
        }
      }
    },

    didInsertElement: function didInsertElement() {
      this.setupContainer();
      this._computeEdges();
      this._initializeScrollState();
      this._scheduleUpdate();
      // Check are we in dev environment
      if (getOwner(this).resolveRegistration('config:environment').environment === 'development') {
        this._checkCssRules();
      }
    },

    // –––––––––––––– Setup/Teardown
    setupContainer: function setupContainer() {
      var containerSelector = this.get('containerSelector');
      var container = undefined;

      if (containerSelector === 'body') {
        container = window;
      } else {
        var $container = containerSelector ? this.$().closest(containerSelector) : this.$().parent();

        container = $container.get(0);

        $container.css({
          '-webkit-overflow-scrolling': 'touch',
          'overflow-scrolling': 'touch',
          'overflow-y': 'scroll'
        });
      }

      this._container = container;
      this.setupHandlers();
    },

    setupHandlers: function setupHandlers() {
      var _this5 = this;

      var container = this._container;
      var onScrollMethod = function onScrollMethod(dY) {
        if (_this5._isPrepending) {
          return;
        }
        _this5._scrollIsForward = dY > 0;

        _this5._scheduleUpdate();
      };

      var onResizeMethod = function onResizeMethod() {
        _this5._computeEdges();
      };
      var onRebuildMethod = function onRebuildMethod(dY, dX) {
        if (_this5._isPrepending) {
          return;
        }

        _this5._scrollIsForward = dY > 0;
        _this5._computeEdges();
        _this5._scheduleUpdate();
      };

      this.radar.setState({
        telescope: container,
        sky: this.element,
        minimumMovement: Math.floor(this.defaultHeight / 2),
        alwaysRemeasure: this.alwaysRemeasure
      });
      this.radar.didResizeSatellites = onResizeMethod;
      this.radar.didAdjustPosition = onResizeMethod;
      this.radar.didShiftSatellites = onScrollMethod;
      this.radar.didRebuild = onRebuildMethod;
    },

    _initializeScrollState: function _initializeScrollState() {
      var idForFirstItem = this.get('idForFirstItem');

      if (this.scrollPosition) {
        this.radar.telescope.scrollTop = this.scrollPosition;
      } else if (this.get('renderFromLast')) {
        var last = this.$().get(0).lastElementChild;

        this.set('__isInitializingFromLast', true);
        if (last) {
          last.scrollIntoView(false);
        }
      } else if (idForFirstItem) {
        var content = this.get('content');
        var firstVisibleIndex = undefined;

        for (var i = 0; i < get(content, 'length'); i++) {
          if (idForFirstItem === this.keyForItem(valueForIndex(content, i), i)) {
            firstVisibleIndex = i;
          }
        }
        this.radar.telescope.scrollTop = (firstVisibleIndex || 0) * this.__getEstimatedDefaultHeight();
      }
    },

    /*
     * Remove the event handlers for this instance
     * and teardown any temporarily cached data.
     *
     * if storageKey is set, caches much of it's
     * state in order to quickly reboot to the same
     * scroll position on relaunch.
     */
    willDestroyElement: function willDestroyElement() {
      this._destroyCollection();
    },

    willDestroy: function willDestroy() {
      this._super();
      this._destroyCollection();
    },

    _destroyCollection: function _destroyCollection() {
      // cleanup scroll
      if (this.radar) {
        this.radar.destroy();
        this.radar.didResizeSatellites = null;
        this.radar.didAdjustPosition = null;
        this.radar.didShiftSatellites = null;
        this.radar = null;
      }

      this.set('_content', null);
      this.set('_children', null);
      this._container = null;
      this.__smActionCache = null;

      // clean up scheduled tasks
      _smokeAndMirrorsPrivateScheduler['default'].forget(this._nextUpdate);
      _smokeAndMirrorsPrivateScheduler['default'].forget(this._nextSync);
      _smokeAndMirrorsPrivateScheduler['default'].forget(this._nextScrollSync);
    },

    __prependComponents: function __prependComponents() {
      var _this6 = this;

      this._isPrepending = true;
      _smokeAndMirrorsPrivateScheduler['default'].forget(this._nextUpdate);
      this._nextUpdate = _smokeAndMirrorsPrivateScheduler['default'].schedule('sync', function () {
        _this6.radar.silentNight();
        _this6._updateChildStates();
        _this6._isPrepending = false;
        _this6._nextUpdate = null;
      });
    },

    __getEstimatedDefaultHeight: function __getEstimatedDefaultHeight() {
      var _defaultHeight = this.get('_defaultHeight');

      if (_defaultHeight) {
        return _defaultHeight;
      }

      var defaultHeight = '' + this.get('defaultHeight');

      if (defaultHeight.indexOf('em') === -1) {
        _defaultHeight = parseInt(defaultHeight, 10);
        this.set('_defaultHeight', _defaultHeight);
        return _defaultHeight;
      }

      var element = undefined;

      // use body if rem
      if (defaultHeight.indexOf('rem') !== -1) {
        element = window.document.body;
        _defaultHeight = 1;
      } else {
        element = this.get('element');
        if (!element || !element.parentNode) {
          element = window.document.body;
        } else {
          _defaultHeight = 1;
        }
      }

      var fontSize = window.getComputedStyle(element).getPropertyValue('font-size');

      if (_defaultHeight) {
        _defaultHeight = parseFloat(defaultHeight) * parseFloat(fontSize);
        this.set('_defaultHeight', _defaultHeight);
        return _defaultHeight;
      }

      return parseFloat(defaultHeight) * parseFloat(fontSize);
    },

    /*
     * Calculates pixel boundaries between visible, invisible,
     * and culled content based on the "viewport" height,
     * and the bufferSize.
     *
     * @private
     */
    _edges: null,
    _computeEdges: function _computeEdges() {
      var edges = undefined;

      if (!this.radar || !this.radar.planet) {
        edges = {};
      } else {
        // segment top break points
        this.radar.planet.setState();

        var bufferSize = this.get('bufferSize');
        var rect = this.radar.planet;

        edges = {
          viewportTop: rect.top,
          visibleTop: -1 * bufferSize * rect.height + rect.top,
          viewportBottom: rect.bottom,
          visibleBottom: bufferSize * rect.height + rect.bottom
        };
      }

      this._edges = edges;
      return edges;
    },

    /*
     * Initialize
     */
    _prepareComponent: function _prepareComponent() {
      this.__smActionCache = {
        firstReached: null,
        lastReached: null,
        firstVisibleChanged: null,
        lastVisibleChanged: null
      };

      var collectionTagName = (this.get('tagName') || '').toLowerCase();
      var itemTagName = this.get('itemTagName');

      if (!itemTagName) {
        if (collectionTagName === 'occlusion-collection') {
          itemTagName = 'occlusion-item';
        } else {
          itemTagName = (0, _smokeAndMirrorsUtilsGetTagDescendant['default'])(collectionTagName);
        }
      }
      this.set('itemTagName', itemTagName);
      this.radar = new _smokeAndMirrorsPrivateRadarModelsListRadar['default']({});
    },

    _reflectContentChanges: function _reflectContentChanges() {
      var _this7 = this;

      var content = this.get('_content');

      content.contentArrayDidChange = function (items, offset, removeCount, addCount) {
        if (offset <= _this7.get('_firstVisibleIndex')) {
          _this7.__prependComponents();
        } else {
          if (!removeCount || removeCount < addCount) {
            _this7._scheduleUpdate();
          }
          _this7._scheduleSync();
        }
      };
    },

    _didReceiveAttrs: function _didReceiveAttrs(attrs) {
      var oldArray = attrs.oldAttrs && attrs.oldAttrs.content ? attrs.oldAttrs.content.value : false;
      var newArray = attrs.newAttrs && attrs.newAttrs.content ? attrs.newAttrs.content.value : false;

      if (oldArray && newArray && this._changeIsPrepend(oldArray, newArray)) {
        this.__prependComponents();
      } else {
        if (newArray && (!oldArray || get(oldArray, 'length') <= get(newArray, 'length'))) {
          this._scheduleUpdate();
        }

        this._scheduleSync();
      }
    },

    _changeIsPrepend: function _changeIsPrepend(oldArray, newArray) {
      var lengthDifference = get(newArray, 'length') - get(oldArray, 'length');

      // if either array is empty or the new array is not longer, do not treat as prepend
      if (!get(newArray, 'length') || !get(oldArray, 'length') || lengthDifference <= 0) {
        return false;
      }

      // if the keys at the correct indexes are the same, this is a prepend
      var oldInitialItem = valueForIndex(oldArray, 0);
      var oldInitialKey = this.keyForItem(oldInitialItem, 0);
      var newInitialItem = valueForIndex(newArray, lengthDifference);
      var newInitialKey = this.keyForItem(newInitialItem, lengthDifference);

      return oldInitialKey === newInitialKey;
    },

    _checkCssRules: function _checkCssRules() {
      if (this.$().css('display') !== 'block') {
        Logger.warn('Verical-Collection needs a value of block on display property to function correctly');
      }
      if (isBlank(this.$().css('height'))) {
        Logger.warn('Verical-Collection needs a value on height to function correctly');
      }
      if (isBlank(this.$().css('max-height'))) {
        Logger.warn('Verical-Collection needs a value on max-height to function correctly');
      }
      if (this.$().css('position') !== 'relative') {
        Logger.warn('Vertical-Collection needs a value of relative on position to function correctly');
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {},

    init: function init() {
      this._super();

      this._prepareComponent();
      this.set('_children', _ember['default'].A());

      if (this.get('useContentProxy')) {
        this.set('_content', _smokeAndMirrorsUtilsProxiedArray['default'].call(this, 'content', this.get('key')));
        this._reflectContentChanges();
      } else {
        this.set('_content', computed.oneWay('content'));
        this.set('didReceiveAttrs', this._didReceiveAttrs);
      }
    }
  });

  VerticalCollection.reopenClass({
    positionalParams: ['items']
  });

  exports['default'] = VerticalCollection;
});
/* global Array, parseFloat, Math */