define('ember-cli-crudities/components/cf-buttons', ['exports', 'ember-cli-crudities/templates/components/cf-buttons'], function (exports, _cfButtons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _cfButtons.default,
    tagName: '',
    showSave: true,
    saveText: 'Save',
    saveClass: 'fa-floppy-o',
    saveAndContinueText: 'Save and continue editing',
    saveAndContinueClasses: ['fa-floppy-o', 'fa-pencil'],
    cancelText: 'Cancel',
    cancelClass: 'fa-reply',
    deleteText: 'Delete',
    deleteClass: 'fa-trash'
  });
});