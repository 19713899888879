define('ember-cli-crudities/mixins/with-handle-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    flashMessages: Ember.inject.service(),

    _handleMessage: function _handleMessage(json) {
      var message = json._message;
      if (Ember.isEmpty(message)) {
        return false;
      }
      if (message.type === 'error') {
        message.type = 'danger';
      }
      this.get('flashMessages')[message.type](message.value);
      return true;
    }
  });
});