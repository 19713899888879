define('ember-cli-crudities/components/list-filters', ['exports', 'ember-cli-crudities/templates/components/list-filters'], function (exports, _listFilters) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _listFilters.default,
    tagName: null,
    doFilterValueChanged: function doFilterValueChanged(param, value) {
      this.attrs.parent.filterValueChanged(param, value);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (this.attrs.parent !== undefined) {
        var fields = ['search_enabled', 'searchValue', 'filter_fields', 'filterFields', 'filterValues', '_hidden_filters', '_filter_values'];
        fields.forEach(function (field) {
          if (_this.attrs[field] === undefined) {
            _this.set(field, Ember.computed.alias('parent.' + field));
          }
        });
      }
    }
  });
});