define('ember-cli-crudities/transforms/duration', ['exports', 'ember-data', 'ember-cli-crudities/utils/duration'], function (exports, _emberData, _duration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return (0, _duration.str_to_seconds)(serialized, true);
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});