define('ember-cli-crudities/helpers/query-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.queryString = queryString;
  function queryString(params /*, hash*/) {
    return params[0].map(function (record) {
      return 'ids[]=' + record.get('id');
    }).join('&');
  }

  exports.default = Ember.Helper.helper(queryString);
});