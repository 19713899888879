define('ember-cli-crudities/components/form-null-boolean', ['exports', 'ember-cli-crudities/components/form-select', 'ember-cli-crudities/templates/components/form-select'], function (exports, _formSelect, _formSelect2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formSelect.default.extend({
    layout: _formSelect2.default,

    type: 'null-boolean',

    _placeholder: false,
    _is_bool: true,

    allowNull: true,

    choices: Ember.computed('allowNull', 'extra.preventNull', 'extra.choices', function () {
      var extraChoices = this.get('extra.choices');
      if (extraChoices) {
        return extraChoices;
      }
      var allowNull = this.get('allowNull') && !this.get('extra.preventNull');
      var rv = [];
      if (allowNull) {
        rv.push({
          label: 'None',
          value: null
        });
      }
      return rv.concat([{
        label: 'Yes',
        value: 1
      }, {
        label: 'No',
        value: 0
      }]);
    })
  });
});