define('ember-offline/services/offline', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    state: 'up',
    isUp: Ember.computed.equal('state', 'up'),
    isDown: Ember.computed.equal('state', 'down'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var offline = Ember.get(this, 'offline');

      offline.on('up', function () {
        Ember.set(_this, 'state', 'up');
      });

      offline.on('down', function () {
        Ember.set(_this, 'state', 'down');
      });
    },
    check: function check() {
      return Ember.get(this, 'offline').check();
    }
  });
});