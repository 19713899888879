define('ember-cli-crudities/mixins/filterable-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actionsFilters: null,
    actionsExcludes: null,
    custom_actions: [],

    sortActionsBy: ['position'],
    sortedCustomActions: Ember.computed.sort('customActions', 'sortActionsBy'),

    do_filter_actions: function do_filter_actions(actions, filters, excludes) {
      var filtered = [];
      if (filters) {
        actions.forEach(function (action) {
          if (action.slug === undefined) {
            return;
          }
          if (filters.some(function (filter) {
            var r = new RegExp(filter);
            return action.slug.match(r);
          })) {
            filtered.push(action);
          }
        });
      } else {
        filtered = actions;
      }
      var rv = [];
      if (excludes) {
        filtered.forEach(function (action) {
          if (action.slug === undefined) {
            rv.push(action);
            return;
          }
          if (!excludes.some(function (filter) {
            var r = new RegExp(filter);
            return action.slug.match(r);
          })) {
            rv.push(action);
          }
        });
      } else {
        rv = filtered;
      }
      return rv;
    },


    customActions: Ember.computed('custom_actions', 'actionsFilters', 'actionsExcludes', function () {
      return this.do_filter_actions(this.get('custom_actions'), this.get('actionsFilters'), this.get('actionsExcludes'));
    })
  });
});