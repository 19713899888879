define('smoke-and-mirrors/-debug/helpers', ['exports', 'ember'], function (exports, _ember) {
  exports.instrument = instrument;
  exports.debug = debug;
  exports.assert = assert;
  exports.warn = warn;
  exports.deprecate = deprecate;
  exports.stripInProduction = stripInProduction;
  var emberAssert = _ember['default'].assert;
  var emberWarn = _ember['default'].warn;
  var emberDeprecate = _ember['default'].deprecate;
  var Logger = _ember['default'].Logger;

  function instrument(cb) {
    cb();
  }

  function debug() {
    Logger.debug.apply(Logger, arguments);
  }

  function assert() {
    emberAssert.apply(undefined, arguments);
  }

  function warn() {
    emberWarn.apply(undefined, arguments);
  }

  function deprecate() {
    emberDeprecate.apply(undefined, arguments);
  }

  function stripInProduction(cb) {
    cb();
  }
});