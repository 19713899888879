define('smoke-and-mirrors/-debug/edge-visualization/debug-mixin', ['exports', 'ember', 'smoke-and-mirrors/-debug/edge-visualization/visualization'], function (exports, _ember, _smokeAndMirrorsDebugEdgeVisualizationVisualization) {
  var computed = _ember['default'].computed;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    debug: false,
    showEdges: computed.alias('debug'),

    _nextVisualization: null,

    toggleEdgeVisualization: function toggleEdgeVisualization() {
      this.toggleProperty('debug');
    },

    visualization: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();
      if (this.get('debug')) {
        this.visualization = new _smokeAndMirrorsDebugEdgeVisualizationVisualization['default'](this);
        requestAnimationFrame(function () {
          _this.visualize();
        });
      }
    },

    visualize: function visualize() {
      var _this2 = this;

      if (!this.get('debug')) {
        if (this.visualization) {
          this.visualization.destroy();
          this.visualization = null;
        }
        return;
      }

      if (this.visualization) {
        this.visualization.render();
        requestAnimationFrame(function () {
          _this2.visualize();
        });
      }
    },

    willDestroy: function willDestroy() {
      this._super();
      if (this.visualization) {
        this.visualization.destroy();
        this.visualization = null;
      }
    }
  });
});