define('ember-cli-crudities/components/form-tomany-stack', ['exports', 'ember-cli-crudities/components/tomany-base', 'ember-cli-crudities/templates/components/form-tomany-stack', 'ember-cli-crudities/mixins/with-custom-actions'], function (exports, _tomanyBase, _formTomanyStack, _withCustomActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tomanyBase.default.extend(_withCustomActions.default, {
    layout: _formTomanyStack.default,
    classNames: ['tomany-stack'],

    sortBy: ['id'],

    actionColumns: Ember.computed('field.extra.customActions', 'field.extra.customActions.length', 'field.extra.allowOpen', function () {
      var rv = parseInt(this.get('custom_actions.length') ? this.get('custom_actions.length') : 0) + parseInt(this.get('field.extra.allowOpen') ? 1 : 0);
      return rv > 0 ? 1 : 0;
    }),

    column_count: Ember.computed('field.fields.length', 'actionColumns', 'field.extra.sortableBy', 'field.extra.preventDelete', function () {
      var readonly = this.get('readonly');
      var preventDelete = this.get('field.extra.preventDelete');
      return parseInt((readonly || preventDelete) !== true ? 2 : 1) + parseInt(this.get('field.extra.sortableBy') ? 1 : 0) + parseInt(this.get('actionColumns') ? this.get('actionColumns') : 0);
    }),

    padderColspan: Ember.computed('field.fields.length', 'column_count', 'actionColumns', function () {
      return this.get('column_count') - parseInt(this.get('field.fields.length')) - this.get('actionColumns') - parseInt(this.get('field.extra.preventDelete') ? 1 : 0);
    }),

    sorted: Ember.computed.sort('related', 'sortBy'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super();
      var sortBy = this.get('field.extra.sortableBy');
      if (sortBy !== undefined) {
        this.set('sortBy', [sortBy]);
      }
    },


    actions: {
      reorderItems: function reorderItems(items) {
        var _this = this;

        items.forEach(function (item, index) {
          item.set(_this.get('field.extra.sortableBy'), index + 1);
          _this.saveMe();
        });
      }
    }
  });
});