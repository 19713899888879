define('ember-cli-crudities/helpers/filter-for-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.filterForField = filterForField;
  function filterForField(params /*, hash*/) {
    if (params.length !== 2) {
      return '';
    }
    var filtered = params[1].filter(function (field) {
      return field.field === params[0];
    });
    if (filtered.length > 0) {
      return filtered[0].filter;
    }
    return '';
  }

  exports.default = Ember.Helper.helper(filterForField);
});