define('ember-cli-flash/components/flash-message', ['exports', 'ember-cli-flash/templates/components/flash-message'], function (exports, _flashMessage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      and = _Ember$computed.and,
      bool = _Ember$computed.bool,
      readOnly = _Ember$computed.readOnly,
      not = _Ember$computed.not;
  var _Ember$run = Ember.run,
      next = _Ember$run.next,
      cancel = _Ember$run.cancel;
  exports.default = Ember.Component.extend({
    layout: _flashMessage.default,
    active: false,
    messageStyle: 'bootstrap',
    classNames: ['flash-message'],
    classNameBindings: ['alertType', 'active', 'exiting'],

    showProgress: readOnly('flash.showProgress'),
    notExiting: not('exiting'),
    showProgressBar: and('showProgress', 'notExiting'),
    exiting: readOnly('flash.exiting'),
    hasBlock: bool('template').readOnly(),

    alertType: Ember.computed('flash.type', {
      get: function get() {
        var flashType = Ember.getWithDefault(this, 'flash.type', '');
        var messageStyle = Ember.getWithDefault(this, 'messageStyle', '');
        var prefix = 'alert alert-';

        if (messageStyle === 'foundation') {
          prefix = 'alert-box ';
        }

        return '' + prefix + flashType;
      }
    }),

    flashType: Ember.computed('flash.type', {
      get: function get() {
        var flashType = Ember.getWithDefault(this, 'flash.type', '');

        return Ember.String.classify(flashType);
      }
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var pendingSet = next(this, function () {
        Ember.set(_this, 'active', true);
      });
      Ember.set(this, 'pendingSet', pendingSet);
    },


    progressDuration: Ember.computed('flash.showProgress', {
      get: function get() {
        if (!Ember.get(this, 'flash.showProgress')) {
          return false;
        }

        var duration = Ember.getWithDefault(this, 'flash.timeout', 0);

        return Ember.String.htmlSafe('transition-duration: ' + duration + 'ms');
      }
    }),

    click: function click() {
      var destroyOnClick = Ember.getWithDefault(this, 'flash.destroyOnClick', true);

      if (destroyOnClick) {
        this._destroyFlashMessage();
      }
    },
    mouseEnter: function mouseEnter() {
      var flash = Ember.get(this, 'flash');
      if (Ember.isPresent(flash)) {
        flash.preventExit();
      }
    },
    mouseLeave: function mouseLeave() {
      var flash = Ember.get(this, 'flash');
      if (Ember.isPresent(flash) && !Ember.get(flash, 'exiting')) {
        flash.allowExit();
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this._destroyFlashMessage();
      cancel(Ember.get(this, 'pendingSet'));
    },


    // private
    _destroyFlashMessage: function _destroyFlashMessage() {
      var flash = Ember.getWithDefault(this, 'flash', false);

      if (flash) {
        flash.destroyMessage();
      }
    },


    actions: {
      close: function close() {
        this._destroyFlashMessage();
      }
    }
  });
});