define('ember-cli-crudities/components/form-image-url', ['exports', 'ember-cli-crudities/components/form-image', 'ember-cli-crudities/templates/components/form-image-url'], function (exports, _formImage, _formImageUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formImage.default.extend({
    layout: _formImageUrl.default,
    type: 'url'
  });
});