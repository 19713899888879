define('smoke-and-mirrors/-private/radar/models/list-satellite', ['exports', 'smoke-and-mirrors/-private/radar/models/satellite', 'perf-primitives/fast-array'], function (exports, _smokeAndMirrorsPrivateRadarModelsSatellite, _perfPrimitivesFastArray) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

  var LIST_SAT_POOL = new _perfPrimitivesFastArray['default'](200, 'ListSatellite Pool');

  var ListSatellite = (function (_Satellite) {
    _inherits(ListSatellite, _Satellite);

    function ListSatellite() {
      _get(Object.getPrototypeOf(ListSatellite.prototype), 'constructor', this).apply(this, arguments);
    }

    _createClass(ListSatellite, [{
      key: 'heightDidChange',
      value: function heightDidChange(dY) {
        this.radar._adjust(this, { dX: 0, dY: dY });
      }
    }, {
      key: 'next',
      value: function next() {
        var nextComponent = this.component.next();

        return nextComponent ? nextComponent.satellite : null;
      }
    }, {
      key: 'prev',
      value: function prev() {
        var prevComponent = this.component.prev();

        return prevComponent ? prevComponent.satellite : null;
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        this._destroy();

        LIST_SAT_POOL.push(this);
      }
    }], [{
      key: 'create',
      value: function create(options) {
        var sat = LIST_SAT_POOL.pop();

        if (sat) {
          sat.init(options);
          return sat;
        }

        return new ListSatellite(options);
      }
    }]);

    return ListSatellite;
  })(_smokeAndMirrorsPrivateRadarModelsSatellite['default']);

  exports['default'] = ListSatellite;
});