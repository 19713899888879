define('ember-cli-crudities/components/base-collection', ['exports', 'ember-cli-crudities/mixins/configurable-loader'], function (exports, _configurableLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_configurableLoader.default, {
    modelLoader: Ember.inject.service(),

    fetch_meta: true,
    is_ready: false,

    custom_actions: [],
    bulk_actions: [],
    ordering_fields: [],
    filter_fields: [],
    conditional_format: {},

    __horiClass: 'col-sm-3',
    _horiClass: Ember.computed.or('horiClass', '__horiClass'),
    __inputClass: 'col-sm-9',
    _inputClass: Ember.computed.or('inputClass', '__inputClass'),

    fieldsChanged: function fieldsChanged() {
      var _this = this;

      var rv = Ember.A();
      var innerHoriClass = this.get('_horiClass');
      var innerInputClass = this.get('_inputClass');
      var fields = this.get('field.fields');
      if (!Ember.isEmpty(fields)) {
        try {
          fields.forEach(function (field) {
            if (Ember.isEmpty(field.horiClass)) {
              field.horiClass = innerHoriClass;
            }
            if (Ember.isEmpty(field.inputClass)) {
              field.inputClass = innerInputClass;
            }
            if (!Ember.isEmpty(field.target_model) && field.targetModel === undefined) {
              var target_model = _this.get('store').findAll(field.model);
              field.targetModel = target_model;
            }
            rv.pushObject(new Ember.Object(field));
          });
        } catch (e) {
          console.warn(e);
        }
      }
      this.set('fields', rv);
      if (this.get('model.content') !== undefined) {
        this.get('model').addObserver('content.isLoaded', this._update_ready.bind(this));
        this._update_ready();
      } else {
        this.set('is_ready', true);
      }
    },

    _update_ready: function _update_ready() {
      var loaded = this.get('model.content.isLoaded');
      if (loaded && !this.get('isDestroyed')) {
        this.set('is_ready', true);
        this.get('model').removeObserver('content.isLoaded', this._update_ready.bind(this));
      }
    },
    willDestroyElement: function willDestroyElement() {
      try {
        this.get('model').removeObserver('content.isLoaded', this._update_ready.bind(this));
      } catch (e) {
        // do nothing;
      }
    },
    save: function save() {
      var do_save = this.get('field.extra.save');
      if (do_save === true) {
        this.get('model').then(function (model) {
          model.save();
        });
      }
    },
    delete: function _delete() {
      var do_save = this.get('field.extra.save');
      if (do_save === true) {
        this.get('model').then(function (model) {
          model.destroyRecord();
        });
      }
    },
    _fetchMeta: function _fetchMeta(model) {
      var _this2 = this;

      if (this.get('fetch_meta') === true) {
        try {
          var fieldset = this.get('field');
          var modelLoader = this.get('modelLoader');
          var inflector = new Ember.Inflector(Ember.Inflector.defaultRules);

          var model_name = this.get('field.extra.related_model');
          if (model_name === undefined) {
            model_name = model.get('content._internalModel.type.modelName');
          }
          if (model_name === undefined) {
            model_name = model.get('content.type.modelName');
          }
          if (model_name === undefined) {
            model_name = model.get('_internalModel.type.modelName');
          }
          if (model_name === undefined) {
            model_name = model.get('type.modelName');
          }
          if (model_name === undefined) {
            throw 'Undefined model_name';
          }
          var plural_name = model_name.split('/');
          plural_name[1] = inflector.pluralize(plural_name[1]);

          modelLoader.get_model_meta(plural_name[0], plural_name[1]).then(function (meta) {
            var merged = modelLoader.merge_fields_fieldset(meta.fields, fieldset);
            if (!_this2.get('isDestroyed') && !Ember.isEmpty(fieldset.fields)) {
              Ember.set(fieldset, 'fields', merged.fields);
              var def = {};
              meta.fields.forEach(function (field) {
                var field_default = Ember.get(field, 'extra.default');
                if (field_default) {
                  def[Ember.get(field, 'name')] = field_default;
                }
              });
              _this2.set('_defaults', def);
              _this2.set('field', fieldset);
              _this2.set('fetch_meta', false);

              var items = ['custom_actions', 'bulk_actions', 'ordering_fields', 'filter_fields', 'conditional_formatting'];
              items.forEach(function (item) {
                _this2.set(item, meta[item]);
              });

              _this2.fieldsChanged();
            } else if (Ember.isEmpty(fieldset.fields)) {
              console.warn('Empty fieldset for', plural_name);
            }
          }, function (err) {
            console.error('while loading meta', err);
          });
        } catch (e) {
          console.error('undefined model_name', e);
        }
      } else {
        this.fieldsChanged();
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this3 = this;

      var model = this.get('model');
      if (model !== undefined && model !== null && model.hasOwnProperty('isFulfilled')) {
        this.get('model').then(function (model) {
          _this3._fetchMeta(model);
        });
      } else if (model !== undefined) {
        this._fetchMeta(model);
      } else {
        console.error(model, 'is undefined', this.get('field.name'));
      }
    }
  });
});