define('ember-cli-crudities/components/form-manytomany-lists', ['exports', 'ember-cli-crudities/components/foreignkey-base', 'ember-cli-crudities/templates/components/form-manytomany-lists'], function (exports, _foreignkeyBase, _formManytomanyLists) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _foreignkeyBase.default.extend({
    layout: _formManytomanyLists.default,
    type: 'manytomany',

    selectedAvailable: new Ember.A(),
    selectedValue: new Ember.A(),
    autocomplete: true,
    _search: 'simple',
    filterValue: undefined,

    available: Ember.computed('choices', 'value', {
      get: function get() {
        var value = this.get('value');
        var valueIds = void 0;
        if (value) {
          valueIds = value.map(function (val) {
            return String(val.get('id'));
          });
        } else {
          valueIds = new Ember.A();
        }

        var choices = this.get('choices');
        return new Ember.A(choices.filter(function (item) {
          return valueIds.indexOf(String(item.get('id'))) === -1;
        }));
      },
      set: function set(key, value) {
        return new Ember.A(value);
      }
    }),

    _clean: function _clean(content) {
      var _this = this;

      if (content === undefined) {
        content = this.get('content');
      }
      content.then(function (items) {
        var possibleIds = items.map(function (item) {
          return String(item.get('id'));
        });
        _this.set('value', _this.get('value').filter(function (item) {
          var id = String(item.get('id'));
          return possibleIds.indexOf(id) !== -1;
        }));
      });
    },
    changeSelection: function changeSelection(container, selection) {
      var selected = [];
      for (var i = 0; i < selection.target.selectedOptions.length; i++) {
        selected.push(selection.target.selectedOptions[i].value);
      }
      var choices = this.get('choices');
      this.set(container, choices.filter(function (item) {
        return selected.indexOf(item.get('id')) !== -1;
      }));
    },


    actions: {
      changeSelectedAvailable: function changeSelectedAvailable(selection) {
        this.changeSelection('selectedAvailable', selection);
      },
      changeSelectedValue: function changeSelectedValue(selection) {
        this.changeSelection('selectedValue', selection);
      },
      move: function move(origin, target) {
        var targetItems = this.get(target);
        var originItems = this.get(origin);
        if (this.get('search') === 'simple' || target !== 'available') {
          originItems.forEach(function (item) {
            targetItems.pushObject(item);
          });
        }
        if (origin === 'selectedValue' || origin === 'value') {
          var ids = originItems.map(function (item) {
            return String(item.get('id'));
          });
          this.set('value', this.get('value').filter(function (item) {
            var id = String(item.get('id'));
            return ids.indexOf(id) === -1;
          }));
        }
      },
      filter: function filter(e) {
        this.send('search', e.target.value);
      }
    }
  });
});