define('smoke-and-mirrors/-private/radar/utils/scroll-handler', ['exports', 'smoke-and-mirrors/-private/scheduler'], function (exports, _smokeAndMirrorsPrivateScheduler) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  exports.addScrollHandler = addScrollHandler;
  exports.removeScrollHandler = removeScrollHandler;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var DEFAULT_ARRAY_SIZE = 10;

  var ScrollHandler = (function () {
    function ScrollHandler() {
      this.elements = new Array(DEFAULT_ARRAY_SIZE);
      this.maxLength = DEFAULT_ARRAY_SIZE;
      this.length = 0;
      this.handlers = new Array(DEFAULT_ARRAY_SIZE);
      this.isPolling = false;
    }

    _createClass(ScrollHandler, [{
      key: 'addElementHandler',
      value: function addElementHandler(element, handler) {
        var index = this.elements.indexOf(element);

        if (index === -1) {
          index = this.length++;

          if (index === this.maxLength) {
            this.maxLength *= 2;
            this.elements.length = this.maxLength;
            this.handlers.length = this.maxLength;
          }

          this.elements[index] = element;
          this.handlers[index] = { top: undefined, left: undefined, handlers: [handler] };
        } else {
          var handlers = this.handlers[index].handlers;

          handlers.push(handler);
        }

        if (!this.isPolling) {
          this.poll();
        }
      }
    }, {
      key: 'removeElementHandler',
      value: function removeElementHandler(element, handler) {
        var index = this.elements.indexOf(element);
        var elementCache = this.handlers[index];

        if (elementCache && elementCache.handlers) {
          var _index = elementCache.handlers.indexOf(handler);

          if (_index === -1) {
            throw new Error('Attempted to remove an unattached handler');
          }

          elementCache.handlers.splice(_index, 1);

          // cleanup element entirely if needed
          if (!elementCache.handlers.length) {
            this.handlers.splice(_index, 1);

            _index = this.elements.indexOf(element);
            this.elements.splice(_index, 1);
            this.length--;
            this.maxLength--;
          }
        } else {
          throw new Error('Attempted to remove an unattached handler');
        }
      }
    }, {
      key: 'poll',
      value: function poll() {
        var _this = this;

        this.isPolling = true;

        _smokeAndMirrorsPrivateScheduler['default'].schedule('sync', function () {
          for (var i = 0; i < _this.length; i++) {
            var element = _this.elements[i];
            var info = _this.handlers[i];
            var cachedTop = element.scrollTop;
            var cachedLeft = element.scrollLeft;
            var topChanged = cachedTop !== info.top && info.top !== undefined;
            var leftChanged = cachedLeft !== info.left && info.left !== undefined;

            info.top = cachedTop;
            info.left = cachedLeft;

            if (topChanged || leftChanged) {
              Promise.resolve(info).then(function (info) {
                for (var j = 0; j < info.handlers.length; j++) {
                  info.handlers[j].call(null, { top: info.top, left: info.left });
                }
              });
            }
          }

          _this.isPolling = _this.length > 0;
          if (_this.isPolling) {
            _this.poll();
          }
        });
      }
    }]);

    return ScrollHandler;
  })();

  exports.ScrollHandler = ScrollHandler;

  var instance = new ScrollHandler();

  function addScrollHandler(element, handler) {
    instance.addElementHandler(element, handler);
  }

  function removeScrollHandler(element, handler) {
    instance.removeElementHandler(element, handler);
  }

  exports['default'] = instance;
});