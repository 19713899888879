define('ember-cli-crudities/mixins/with-validation', ['exports', 'ember-cli-crudities/utils/validation', 'ember-cli-crudities/mixins/condition-evaluator'], function (exports, _validation, _conditionEvaluator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_conditionEvaluator.default, {

    doCt: 0,

    _do_get_model: function _do_get_model(value) {
      if (value.hasOwnProperty('isFulfilled')) {
        return value.get('content');
      }
      return value;
    },
    _is_displayed: function _is_displayed(field, model) {
      var condition = Ember.get(field, 'extra.display_condition');
      if (Ember.isEmpty(condition)) {
        return true;
      }
      return this.evaluate(condition, model.get(Ember.get(condition, 'property_path')));
    },
    _do_method: function _do_method(record, method, resolve, reject) {
      var _this = this;

      if (method === 'save') {
        if (record !== undefined && record.get('isSoftDeleted') !== true) {
          record[method]().then(function (saved) {
            console.log({ saved: saved, state: saved.get('currentState') });
            resolve(saved);
          }, function (err) {
            console.log('rejecting', { record: record, state: record.get('currentState') });
            _this.get('flashMessages').danger('record is not saved');
            _this.get('flashMessages').danger('record is not saved');
            reject(err);
          });
        } else {
          record.set('isSoftDeleted', false);
          if (!record.get('isDeleted')) {
            record.destroyRecord().then(resolve, reject);
          } else {
            resolve(record);
          }
        }
      } else if (method === 'validate') {
        resolve(record);
      } else {
        record.set('isSoftDeleted', false);
        if (!record.get('isDeleted')) {
          record[method]().then(resolve, reject);
        } else {
          resolve(record);
        }
      }
    },
    _do_for_fields: function _do_for_fields(saved_record, fieldsets, method, top_promises, models, prefix, backlog) {
      var _this2 = this;

      var breakException = {};
      fieldsets.forEach(function (fieldset) {
        if (fieldset.fields !== undefined) {
          fieldset.fields.forEach(function (field) {
            if (!_this2._is_displayed(field, saved_record)) {
              return;
            }
            if (field.widget && field.widget.split('-')[0] === 'tomany') {
              top_promises.push(new Ember.RSVP.Promise(function (resolve, reject) {
                var promises = [];
                var appConfig = Ember.getOwner(_this2).resolveRegistration('config:environment');
                var foundSaveMe = Ember.get(appConfig, 'ember-cli-crudities.forceSaveUnchanged');
                if (!foundSaveMe) {
                  try {
                    _this2.get('saveMes').forEach(function (saveMe) {
                      var parts = [];
                      saveMe.split('.').forEach(function (part) {
                        parts.push(part);
                        var name = parts.join('.');
                        if (name === '' + prefix + field.name) {
                          foundSaveMe = true;
                          throw breakException;
                        }
                      });
                    });
                  } catch (e) {
                    if (e !== breakException) {
                      throw e;
                    }
                  }
                }
                if (foundSaveMe) {
                  saved_record.get(field.name).then(function (related) {
                    related.forEach(function (related_record) {
                      if (models.indexOf(related_record) === -1 || backlog.indexOf(related_record) !== 1) {
                        promises.push(new Ember.RSVP.Promise(function (resolve_bottom, reject_bottom) {
                          _this2._do_for_all(related_record, [field], method, method === 'save' && field.extra && field.extra.saveTwice, field.name ? '' + prefix + field.name + '.' : prefix, backlog, false).then(function (saved_related) {
                            models.push(saved_related);
                            resolve_bottom(related_record);
                            Ember.set(fieldset, 'isError', false);
                          }, function (err) {
                            Ember.set(fieldset, 'isError', true);
                            reject_bottom(err);
                          });
                        }));
                      }
                    });
                  }, reject);
                }
                Ember.RSVP.allSettled(promises).then(function (result) {
                  var allGood = true;
                  result.forEach(function (r) {
                    if (r.state === 'rejected') {
                      allGood = false;
                    }
                  });
                  // Upstream bug?
                  // sometimes the array received as `result` is empty
                  // this isn't the best fix but it works
                  if (result.length !== promises.length) {
                    promises.forEach(function (promise) {
                      promise.then(resolve, function (err) {
                        Ember.set(fieldset, 'isError', true);
                        reject(err);
                      });
                    });
                  } else if (allGood) {
                    resolve(saved_record);
                  } else {
                    reject(result);
                  }
                }, reject);
              }));
            } else if (field.widget && field.widget === 'foreignkey' && field.extra && field.extra.expand) {
              top_promises.push(new Ember.RSVP.Promise(function (resolve_bottom, reject_bottom) {
                saved_record.get(field.name).then(function (related_record) {
                  _this2._do_for_all(related_record, [field], method, method === 'save' && field.extra && field.extra.saveTwice, field.name ? '' + prefix + field.name + '.' : prefix, backlog, false).then(function (saved_related) {
                    models.push(saved_related);
                    resolve_bottom(related_record);
                    Ember.set(fieldset, 'isError', false);
                  }, function (err) {
                    Ember.set(fieldset, 'isError', true);
                    reject_bottom(err);
                  });
                }, reject_bottom);
              }));
            } else if (field.widget && field.widget === 'fieldset' && _this2._is_displayed(field, saved_record)) {
              _this2._do_for_fields(saved_record, [field], method, top_promises, models, prefix);
            } else if (field.widget && field.widget === 'tabset') {
              _this2._do_for_fields(saved_record, field.tabs, method, top_promises, models, prefix);
            }
          });
          if (method === 'validate' && _this2._is_displayed(fieldset, saved_record)) {
            top_promises.push((0, _validation.validate_model)(saved_record, fieldset.fields));
          }
        } else if (method === 'validate' && _this2._is_displayed(fieldset, saved_record)) {
          top_promises.push((0, _validation.validate_model)(saved_record, fieldset));
        }
      });
    },
    _check_pending_rels: function _check_pending_rels(record, fieldsets) {
      var _this3 = this;

      var pending = [];
      fieldsets.forEach(function (fieldset) {
        if (fieldset.fields !== undefined) {
          fieldset.fields.forEach(function (field) {
            if (field.widget && field.widget === 'foreignkey') {
              if (Ember.get(record, field.name) !== undefined && Ember.get(record, field.name + '.isNew')) {
                pending.push(Ember.get(record, field.name));
              }
            } else if (field.widget && (field.widget.split('-')[0] === 'manytomany' || Ember.get(field, 'extra.treatAsM2M') === true)) {
              var toRemove = [];
              Ember.get(record, field.name).forEach(function (related) {
                if (related !== undefined && Ember.get(related, 'isNew')) {
                  pending.push(Ember.get(record, field.name));
                } else if (related !== undefined && Ember.get(related, 'isSoftDeleted') === true) {
                  toRemove.push(related);
                  Ember.set(related, 'isSoftDeleted', false);
                }
              });
              Ember.get(record, field.name).removeObjects(toRemove);
            } else if (field.widget && field.widget === 'fieldset' && field.fields) {
              pending = pending.concat(_this3._check_pending_rels(record, [field]));
            } else if (field.widget && field.widget === 'tabset' && field.tabs) {
              pending = pending.concat(_this3._check_pending_rels(record, [field.tabs]));
            }
          });
        }
      });
      return pending;
    },
    _do_for_all: function _do_for_all(record, fieldsets, method, twice, prefix, backlog, is_top) {
      var _this4 = this;

      if (is_top === undefined) {
        is_top = true;
      }
      if (prefix === undefined) {
        prefix = '';
      }
      if (backlog === undefined) {
        backlog = [];
      }
      if (is_top === true) {
        this.doCt = 0;
      }
      var top_promises = [];
      var models = [];
      var mybacklog = [];
      /** TODO: Can probably be improved with ember-concurency */
      var rv = new Ember.RSVP.Promise(function (resolve_top, reject_top) {
        var promise = void 0;
        if (method === 'save') {
          mybacklog = _this4._check_pending_rels(record, fieldsets);
        }
        if (method === 'rollbackAttributes') {
          promise = new Ember.RSVP.Promise(function (resolve) {
            if (!Ember.isEmpty(record)) {
              record[method]();
              record.set('isSoftDeleted', false);
            }
            resolve(record);
          });
        } else if (!twice || record !== undefined && record.get('isNew')) {
          if (mybacklog.length === 0) {
            if (record !== null) {
              promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (record.then) {
                  record.then(function (r) {
                    _this4._do_method(r, method, resolve, reject);
                  }, reject);
                } else {
                  _this4._do_method(record, method, resolve, reject);
                }
              });
            }
            var index = backlog.indexOf(record);
            if (index !== -1) {
              backlog.splice(index, 1);
            }
          } else {
            if (backlog.indexOf(record) === -1) {
              backlog.push(record);
            }
            promise = Ember.RSVP.resolve(record);
          }
        } else {
          promise = Ember.RSVP.resolve(record);
        }
        if (promise !== undefined) {
          promise.then(function (saved_record) {
            console.log({ saved_record: saved_record });
            models.push(saved_record);
            _this4._do_for_fields(saved_record, fieldsets, method, top_promises, models, prefix, mybacklog);
            Ember.RSVP.allSettled(top_promises).then(function (result) {
              var allGood = true;
              result.forEach(function (r) {
                if (r.state === 'rejected') {
                  console.warn(r);
                  allGood = false;
                }
              });
              if (!allGood) {
                console.error('error in related records');
                if (_this4.get('forceValidation') === true) {
                  reject_top('error in related records');
                  return;
                }
              }
              resolve_top(record);
            }, function (err) {
              console.log('a');
              reject_top(err);
            });
          }, function (err) {
            console.log('b');
            reject_top(err);
          });
        } else {
          resolve_top(record);
        }
      });
      if (twice) {
        if (mybacklog.length === 0) {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            rv.then(function (record) {
              record[method]().then(function (saved_record) {
                var jndex = backlog.indexOf(record);
                if (jndex !== -1) {
                  backlog.splice(jndex, 1);
                }
                resolve(saved_record);
              }, reject);
            }, reject);
          });
        }
        if (backlog.indexOf(record) === -1) {
          backlog.push(record);
        }
      }
      if (mybacklog.length === 0) {
        var jndex = backlog.indexOf(record);
        if (jndex !== -1) {
          backlog.splice(jndex, 1);
        }
      } else {
        if (backlog.indexOf(record) === -1) {
          backlog.push(record);
        }
      }

      return this._final_do(rv, backlog, method);
    },
    _final_do: function _final_do(rv, backlog, method) {
      var _this5 = this;

      console.log({ method: method, backlog: backlog });
      if (backlog.length > 1) {
        console.log({ backlog: backlog });
        this.doCt++;
        var newRv = new Ember.RSVP.Promise(function (resolve, reject) {
          if (_this5.doCt > 10) {
            reject('over');
          } else {
            rv.then(function (saved_record) {
              Ember.run.later(_this5, function () {
                _this5._final_do(rv, backlog).then(resolve, reject);
              }, 250);
            }, reject);
          }
        });
        return newRv;
      }
      return rv;
    },
    validate: function validate(callback, errorHandling) {
      return this._do_for_all(this.get('model'), this.get('fieldsets'), 'validate').then(callback, errorHandling);
    }
  });
});