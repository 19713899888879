define('ember-cli-crudities/components/form-file', ['exports', 'ember-cli-crudities/templates/components/form-file', 'ember-cli-crudities/mixins/boundvalue'], function (exports, _formFile, _boundvalue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_boundvalue.default, {
    layout: _formFile.default,
    tagName: undefined,
    type: 'file',
    _filename: null,

    fileName: Ember.computed('value', '_filename', function () {
      var value = this.get('value');
      if (value.indexOf('data:') === 0) {
        return this.get('_filename');
      }
      return value;
    }),

    actions: {
      upload: function upload(ev) {
        var _this = this;

        var value = ev.target.files;
        if (value && value[0]) {
          var reader = new FileReader();
          reader.onload = function (e) {
            var data = e.target.result;
            _this.set('value', data);
          };
          reader.readAsDataURL(value[0]);
          this.set('_filename', value[0].name);
        }
      },
      clear: function clear() {
        this.set('value', null);
      },
      validate: function validate(parent) {
        parent.validate();
      }
    }
  });
});