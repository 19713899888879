define('ember-cli-crudities/utils/records-aggregates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = recordsAggregates;
  function recordsAggregates(records, type, property_path, group_by) {
    var rv = {};
    var label = void 0;

    records.forEach(function (record) {
      if (group_by) {
        label = record.get(group_by);
      }
      if (!label) {
        label = '_';
      }
      if (!rv.hasOwnProperty(label)) {
        rv[label] = [0, 0];
      }

      rv[label][1]++;
      rv[label][0] += parseFloat(record.get(property_path));
    });

    for (var prop in rv) {
      if (rv.hasOwnProperty(prop)) {
        switch (type) {
          case 'count':
            rv[prop] = rv[prop][1];
            break;
          case 'sum':
            rv[prop] = rv[prop][0];
            break;
          case 'avg':
            rv[prop] = rv[prop][0] / rv[prop][1];
            break;
        }
      }
    }

    return rv;
  }
});