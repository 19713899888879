define('ember-cli-crudities/components/cl-aggregates', ['exports', 'ember-cli-crudities/templates/components/cl-aggregates'], function (exports, _clAggregates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _clAggregates.default,
    tagName: 'tfoot'
  });
});