// Support for 2.16 - 3.0
var _Ember$__loader$requi = Ember.__loader.require('ember-glimmer/syntax'),
    registerMacros = _Ember$__loader$requi.registerMacros;

var _Ember$__loader$requi2 = Ember.__loader.require('@glimmer/runtime'),
    compileList = _Ember$__loader$requi2.compileList;

registerMacros(function (blocks) {
  blocks.add('let', function (params, hash, _default, inverse, builder) {
    compileList(params, builder);
    builder.invokeStatic(_default, params.length);
  });
});