define('ember-cli-crudities/components/cf-form-errors', ['exports', 'ember-cli-crudities/templates/components/cf-form-errors'], function (exports, _cfFormErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _cfFormErrors.default,
    tagName: ''
  });
});