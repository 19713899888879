define('ember-cli-crudities/components/translatable-field', ['exports', 'ember-cli-crudities/templates/components/translatable-field'], function (exports, _translatableField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _translatableField.default,
    langs: Ember.computed('languages', 'languages.length', 'languages[]', function () {
      var languages = this.get('languages');
      var rv = new Ember.A();
      if (languages === undefined) {
        return rv;
      }
      var first = true;
      languages.forEach(function (language) {
        rv.pushObject({
          long: language,
          short: language.substring(language.length - 2),
          suffix: first ? '' : '_' + language.replace('-', '_'),
          lang: language.substring(0, 2)
        });
        first = false;
      });
      return rv;
    }),

    _current_language: null,
    currentLanguage: Ember.computed('langs', '_current_language', {
      get: function get() {
        var translated = this.get('field.translated');
        if (!translated) {
          return '';
        }
        var currentLanguage = this.get('_current_language');
        if (!currentLanguage) {
          var languages = this.get('langs');
          if (languages === undefined || languages.length === 0) {
            return '';
          }
          var rv = languages[0];
          if (rv) {
            return rv.suffix;
          }
          return '';
        }
        return currentLanguage;
      },
      set: function set(key, value) {
        var langs = this.get('langs');
        var lang = langs.filter(function (l) {
          return l.long === value;
        });
        var rv = '';
        if (value) {
          rv = '_' + value.replace('-', '_');
        }
        if (lang.length > 0) {
          rv = lang[0].suffix;
          this.set('_current_language', rv);
        }
        return rv;
      }
    }),

    actions: {
      setLang: function setLang(lang) {
        this.set('currentLanguage', lang);
      }
    }
  });
});