define('ember-cli-crudities/components/form-manytomany-select', ['exports', 'ember-cli-crudities/components/foreignkey-base', 'ember-cli-crudities/templates/components/form-manytomany-select'], function (exports, _foreignkeyBase, _formManytomanySelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _foreignkeyBase.default.extend({
    layout: _formManytomanySelect.default,
    type: 'manytomany'
  });
});