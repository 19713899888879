define('smoke-and-mirrors/-private/radar/models/element', ['exports', 'perf-primitives/fast-array'], function (exports, _perfPrimitivesFastArray) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var ELEMENT_POOL = new _perfPrimitivesFastArray['default'](200, 'Element Pool');
  var STYLE_POOL = new _perfPrimitivesFastArray['default'](200, 'Style Pool');
  var RECT_POOL = new _perfPrimitivesFastArray['default'](200, 'Rect Pool');

  var LayoutProps = ['position', 'box-sizing', 'float', 'display', 'height', 'width', 'minHeight', 'minWidth', 'maxHeight', 'maxWidth', 'borderTopWidth', 'borderBottomWidth', 'borderLeftWidth', 'borderRightWidth', 'marginTop', 'marginBottom', 'marginLeft', 'marginRight', 'paddingTop', 'paddingBottom', 'paddingLeft', 'paddingRight'];

  exports.LayoutProps = LayoutProps;

  var Style = (function () {
    function Style(values) {
      this.init(values);
    }

    _createClass(Style, [{
      key: 'init',
      value: function init() {
        var values = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

        for (var i = 0; i < LayoutProps.length; i++) {
          this[LayoutProps[i]] = values[LayoutProps[i]];
        }
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        for (var i = 0; i < LayoutProps.length; i++) {
          this[LayoutProps[i]] = undefined;
        }

        STYLE_POOL.push(this);
      }
    }], [{
      key: 'create',
      value: function create(values) {
        var po = STYLE_POOL.pop();

        if (po) {
          po.init(values);
          return po;
        }

        return new Style(values);
      }
    }]);

    return Style;
  })();

  exports.Style = Style;

  var RECT_PROPS = ['top', 'left', 'bottom', 'right', 'width', 'height'];

  var Rect = (function () {
    function Rect(values) {
      this.init(values);
    }

    _createClass(Rect, [{
      key: 'init',
      value: function init() {
        var values = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

        for (var i = 0; i < RECT_PROPS.length; i++) {
          this[RECT_PROPS[i]] = values[RECT_PROPS[i]];
        }
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        for (var i = 0; i < RECT_PROPS.length; i++) {
          this[RECT_PROPS[i]] = undefined;
        }

        RECT_POOL.push(this);
      }
    }], [{
      key: 'create',
      value: function create(values) {
        var po = RECT_POOL.pop();

        if (po) {
          po.init(values);
          return po;
        }

        return new Rect(values);
      }
    }]);

    return Rect;
  })();

  exports.Rect = Rect;

  function makeRect(styles) {
    var s = Rect.create();

    s.left = styles.left || 0;
    s.top = styles.top || 0;
    s.width = styles.width || 0;
    s.height = styles.height || 0;
    s.right = styles.left + styles.width;
    s.bottom = styles.top + styles.height;

    return s;
  }

  var VirtualElement = (function () {
    function VirtualElement(styles, element) {
      this.init(styles, element);
    }

    _createClass(VirtualElement, [{
      key: 'init',
      value: function init() {
        var styles = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
        var element = arguments.length <= 1 || arguments[1] === undefined ? undefined : arguments[1];

        this.element = element;
        this.style = Style.create(styles);
        this.rect = makeRect(styles);
      }
    }, {
      key: 'getComputedStyle',
      value: function getComputedStyle() {
        if (this.element) {
          this.style = new Style(this.element.getComputedStyle());
        }

        // return a copy
        return new Style(this.style);
      }
    }, {
      key: 'getBoundingClientRect',
      value: function getBoundingClientRect() {
        if (this.element) {
          this.rect = new Rect(this.element.getBoundingClientRect());
        }

        return new Rect(this.rect);
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        this.element = undefined;
        this.style = undefined;
        this.rect = undefined;

        ELEMENT_POOL.push(this);
      }
    }], [{
      key: 'create',
      value: function create(styles, element) {
        var po = ELEMENT_POOL.pop();

        if (po) {
          po.init(styles, element);
          return po;
        }

        return new VirtualElement(styles, element);
      }
    }]);

    return VirtualElement;
  })();

  exports['default'] = VirtualElement;
});