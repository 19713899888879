define('ember-cli-crudities/components/custom-action', ['exports', 'ember-cli-crudities/templates/components/custom-action', 'ember-cli-crudities/mixins/with-condition'], function (exports, _customAction, _withCondition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_withCondition.default, {
    layout: _customAction.default,
    _disabled: false,
    toWatch: [['act.disable_condition', '_disabled']],

    isComponent: Ember.computed('act.component', function () {
      var component = this.get('act.component');
      return component !== undefined;
    }),
    isMethod: Ember.computed('act.type', function () {
      var type = this.get('act.type');
      return type === 'modelMethod' || type === 'closureMethod';
    })

  });
});