define('ember-cli-crudities/helpers/conditional-format', ['exports', 'ember-cli-crudities/utils/conditional-format'], function (exports, _conditionalFormat) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.conditionalFormat = conditionalFormat;
  function conditionalFormat(params /*, hash*/) {
    var model = params[0];
    var conditions = params[1];
    return (0, _conditionalFormat.default)(model, conditions);
  }

  exports.default = Ember.Helper.helper(conditionalFormat);
});