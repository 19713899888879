define('ember-cli-crudities/components/list-pagination', ['exports', 'ember-cli-crudities/templates/components/list-pagination'], function (exports, _listPagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _listPagination.default,
    classNames: ['pagination', 'pull-right'],
    hasPages: Ember.computed('meta', function () {
      var meta = this.get('meta');
      if (!meta) {
        return false;
      }
      var currentPage = this.get('currentPage');
      if (meta.next && meta.next > currentPage) {
        return true;
      }
      return meta.previous;
    }),
    pageCount: Ember.computed('meta', function () {
      var next = this.get('meta.next');
      var currentPage = this.get('currentPage');
      if (!next || next < currentPage) {
        var previous = this.get('meta.previous');
        return parseInt(previous) + 1;
      }
      var records = parseInt(this.get('model.length'));
      var count = parseInt(this.get('meta.count'));
      return Math.ceil(count / records);
    }),

    begining: Ember.computed('pageCount', 'currentPage', function () {
      var currentPage = this.get('currentPage');
      var pageCount = this.get('pageCount');
      if (pageCount < 15) {
        return pageCount;
      }
      if (currentPage < 10) {
        return Math.max(currentPage + 2, 5);
      }
      return 5;
    }),

    middle: Ember.computed('pageCount', 'currentPage', function () {
      var currentPage = this.get('currentPage');
      var pageCount = this.get('pageCount');
      if (pageCount < 15 || currentPage < 10 || currentPage + 10 >= pageCount) {
        return false;
      }
      return { from: currentPage - 2, to: currentPage + 3 };
    }),

    end: Ember.computed('pageCount', 'currentPage', function () {
      var currentPage = this.get('currentPage');
      var pageCount = this.get('pageCount');
      if (pageCount < 15) {
        return false;
      }
      if (currentPage >= pageCount - 10) {
        return Math.min(currentPage - 2, pageCount - 4);
      }
      return pageCount - 4;
    })
  });
});