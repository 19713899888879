define('ember-cli-crudities/components/app-breadcrumbs', ['exports', 'ember-cli-crudities/templates/components/app-breadcrumbs'], function (exports, _appBreadcrumbs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _appBreadcrumbs.default,
    tagName: 'ol',
    classNames: 'breadcrumb',
    routing: Ember.inject.service('-routing'),
    mainRoute: 'index',
    mainLabel: 'Dashboard',

    currentRoute: Ember.computed('routing.currentRouteName', function () {
      var route = this.get('routing.currentRouteName');
      var rv = this.get('mainRoute') || 'index';
      if (route === this.get('appRoute') || route === this.get('changeListRoute') || route === this.get('changeFormRoute')) {
        rv = route;
      }
      return rv;
    }),

    routeParams: new Ember.Object({ app_name: null }),

    routeChange: Ember.observer('routing.currentState', function () {
      try {
        var router = this.get('routing.currentState.emberRouter.router');
        if (Ember.isEmpty(router)) {
          var App = Ember.getOwner(this);
          router = App.lookup('router:main').get('currentState.routerJs');
        }
        this.set('routeParams', Ember.get(router, 'currentHandlerInfos')[2].params);
      } catch (e) {
        this.set('routeParams', new Ember.Object());
      }
    }),

    init: function init() {
      this._super();
      this.routeChange();
    }
  });
});