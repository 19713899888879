define('smoke-and-mirrors/-private/radar/utils/supports-passive', ['exports'], function (exports) {
  var supportsPassive = false;

  try {
    var opts = Object.defineProperty({}, 'passive', {
      get: function get() {
        supportsPassive = true;
      }
    });

    window.addEventListener('test', null, opts);
  } catch (e) {}

  exports['default'] = supportsPassive;
});