define('ember-cli-crudities/components/form-secret', ['exports', 'ember-cli-crudities/components/form-input'], function (exports, _formInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formInput.default.extend({
    types: ['password', 'text'],
    icons: ['', '-slash'],
    typeIndex: 0,
    type: Ember.computed('types', 'typeIndex', function () {
      var types = this.get('types');
      var index = this.get('typeIndex');
      return types[index];
    }),
    actionAddonIcon: Ember.computed('icons', 'typeIndex', function () {
      var icons = this.get('icons');
      var index = this.get('typeIndex');
      return 'fa fa-eye' + icons[index];
    }),

    actionAddon: 'toggleType',

    actions: {
      toggleType: function toggleType() {
        this.set('typeIndex', 1 - this.get('typeIndex'));
      }
    }
  });
});