define('ember-cli-crudities/components/form-markdown', ['exports', 'ember-cli-crudities/components/form-textarea', 'ember-cli-crudities/templates/components/form-markdown'], function (exports, _formTextarea, _formMarkdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formTextarea.default.extend({
    layout: _formMarkdown.default,
    type: 'markdown',
    classNames: ['markdown'],

    _hideIcons: ['image', 'fullscreen'],
    _showIcons: ['table', 'code', 'horizontal-rule'],

    hideIcons: Ember.computed.or('extra.hideIcons', '_hideIcons'),
    showIcons: Ember.computed.or('extra.showIcons', '_showIcons'),

    buildSimpleMDEOptions: Ember.computed('hideIcons', 'showIcons', function () {
      return {
        showIcons: this.get('showIcons'),
        hideIcons: this.get('hideIcons')
      };
    })
  });
});