define('ember-cli-crudities/mixins/condition-evaluator', ['exports', 'ember-cli-crudities/utils/evaluator'], function (exports, _evaluator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    evaluate: function evaluate(condition, value) {
      return (0, _evaluator.default)(condition, value);
    }
  });
});