define('ember-cli-crudities/components/record-line', ['exports', 'ember-cli-crudities/templates/components/record-line', 'ember-sortable/mixins/sortable-item', 'ember-cli-crudities/mixins/with-custom-actions', 'ember-cli-crudities/utils/conditional-format'], function (exports, _recordLine, _sortableItem, _withCustomActions, _conditionalFormat) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_sortableItem.default, _withCustomActions.default, {
    layout: _recordLine.default,
    tagName: 'tr',
    classNameBindings: ['state', 'conditionalFormatting'],
    timer: undefined,
    handle: '#none',
    observed: [],

    state: '',
    computeFormatting: function computeFormatting() {
      var model = this.get('model');
      var conditions = this.get('conditionalFormat');
      this.set('conditionalFormatting', (0, _conditionalFormat.default)(model, conditions));
    },
    willDestroy: function willDestroy() {
      var _this = this;

      this._super();
      this.get('observed').forEach(function (item) {
        _this.removeObserver('model.' + item.split('-')[1], _this.computeFormatting.bind(_this));
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this.computeFormatting();
      var conditions = this.get('conditionalFormat');
      var observed = this.get('observed');
      var prop = void 0;
      var i = void 0;
      for (prop in conditions) {
        if (conditions.hasOwnProperty(prop)) {
          for (i = 0; i < conditions[prop].length; i++) {
            if (observed.indexOf(this.get('model.id') + '-' + conditions[prop][i].property_path) === -1) {
              this.addObserver('model.' + conditions[prop][i].property_path, this.computeFormatting.bind(this));
              observed.push(this.get('model.id') + '-' + conditions[prop][i].property_path);
            }
          }
        }
      }

      if (this.attrs.parent !== undefined) {
        var fields = ['display_fields', 'innerClass', 'changeFormRoute', 'app_name', 'label', 'orderable', 'custom_actions'];
        fields.forEach(function (field) {
          if (_this2.attrs[field] === undefined) {
            _this2.set(field, _this2.get('parent.' + field));
          }
        });
      }
    },


    actions: {
      addNew: function addNew() {},
      placeHolder: function placeHolder() {},
      save: function save() /* model, field */{
        var _this3 = this;

        Ember.run.debounce(this, function () {
          _this3.get('model').save().then(function () {
            if (_this3.timer !== undefined) {
              Ember.run.cancel(_this3.timer);
            }
            _this3.set('state', 'success');
            _this3.timer = Ember.run.later(_this3, function () {
              _this3.set('state', '');
            }, 750);
          }).catch(function (err) {
            /* eslint-disable no-console */
            console.error(err);
            /* eslint-enable no-console */
            if (_this3.timer !== undefined) {
              Ember.run.cancel(_this3.timer);
            }
            _this3.set('state', 'danger');
            _this3.timer = Ember.run.later(_this3, function () {
              if (!_this3.get('isDestroyed')) {
                _this3.set('state', '');
              }
            }, 750);
          });
        }, 250);
      }
    }
  });
});