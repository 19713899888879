define('ember-cli-crudities/mixins/change-route', ['exports', 'ember-cli-crudities/mixins/configurable-loader'], function (exports, _configurableLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Mixin.create(_configurableLoader.default, {
    modelLoader: Ember.inject.service('modelLoader'),
    model: function model(params) {
      var modelLoader = this.get('modelLoader');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        modelLoader.ensure_model(params.app_name, params.model_name).then(function (app_info) {
          resolve(_extends({}, params, {
            meta: app_info.meta
          }));
        }).catch(function (err) {
          reject(err);
        });
      });
    }
  });
});