define('ember-cli-crudities/components/form-select', ['exports', 'ember-cli-crudities/templates/components/form-select', 'ember-cli-crudities/mixins/boundvalue'], function (exports, _formSelect, _boundvalue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_boundvalue.default, {
    layout: _formSelect.default,
    tagName: undefined,
    type: 'select',
    _placeholder: '---',
    _other: { value: '__other__', label: 'Other' },
    _is_bool: false,

    placeHolder: Ember.computed.or('field.placeholder', 'placeholder', '_placeholder'),
    isBool: Ember.computed.or('extra.isBool', '_is_bool'),

    choices: Ember.computed('extra.choices', 'extra.choices[]', 'extra.allowOther', function () {
      var choices = new Ember.A(Ember.copy(this.get('extra.choices')));
      if (this.get('extra.allowOther')) {
        choices.pushObject(this.get('_other'));
      }
      return choices;
    }),

    selectedItem: Ember.computed('value', 'choices', 'choices[]', {
      get: function get() {
        var value = this.get('value');
        var isBool = this.get('isBool');
        if (isBool) {
          if (value === true) {
            value = 1;
          } else if (value === false) {
            value = 0;
          } else {
            value = null;
          }
        }
        var filtered = this.get('choices').filter(function (item) {
          return item.value === null && Ember.isEmpty(value) || item.value === value;
        });
        if (filtered.length > 0) {
          return filtered[0];
        }
        if (this.get('extra.allowOther') && this.get('value')) {
          return this.get('_other');
        }
        return null;
      },
      set: function set(key, value) {
        var other = this.get('_other');
        if (value !== null) {
          if (value.value !== other.value) {
            this.set('value', value.value);
          } else {
            this.set('value', '');
          }
        } else {
          this.set('value', null);
        }
        return value;
      }
    }),

    valueStr: Ember.computed('value', 'choices', 'choices[]', function () {
      var value = this.get('value');
      var choice = this.get('choices').filter(function (item) {
        return item.value === value;
      });
      if (choice.length > 0) {
        return choice[0].label;
      }
      return value;
    }),

    actions: {
      change: function change(selectedItem) {
        this.set('selectedItem', selectedItem);
      },
      updateOtherVal: function updateOtherVal(val) {
        var other = this.get('_other');
        if (this.get('selectedItem.value') === other.value) {
          this.set('value', val);
        }
      },
      validate: function validate(parent) {
        parent.validate();
      }
    }
  });
});