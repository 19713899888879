define('ember-cli-crudities/components/form-fieldset', ['exports', 'ember-cli-crudities/components/base-collection', 'ember-cli-crudities/templates/components/form-fieldset'], function (exports, _baseCollection, _formFieldset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseCollection.default.extend({
    layout: _formFieldset.default,

    is_ready: true,

    tagName: 'fieldset',
    classNameBindings: ['class'],
    fields: Ember.A(),
    label: Ember.computed.or('field.title', 'field.label'),
    innerClass: Ember.computed('field.innerClass', function () {
      var innerClass = this.get('field.innerClass');
      if (!Ember.isEmpty(innerClass)) {
        return innerClass;
      }
      return 'col-xs-12 stretch-row';
    }),

    horiClass: Ember.computed.alias('field.horiClass'),
    inputClass: Ember.computed.alias('field.inputClass'),

    onChange: function onChange() {
      // placeholder function
    }
  });
});