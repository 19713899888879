define('ember-cli-crudities/components/cf-custom-actions', ['exports', 'ember-cli-crudities/templates/components/cf-custom-actions'], function (exports, _cfCustomActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _cfCustomActions.default,
    tagName: null,
    actionTagName: 'div'
  });
});